import { Col, Form, IconsCommon, Modal, QButton, QRangePicker, QSelect, Row } from 'quantum_components'
import { Select } from 'antd'
import { FORMATS } from '../../../../constants'
import React, { Fragment, useEffect, useState } from 'react'
import {
  ACTIVE_USER_BUTTON_DATES,
  BUTTON_DATES,
  BUTTON_DATES_NAMES,
  CHART_TYPES,
  DATE_PRESET,
} from '../../../../constants/dashboard'
import { getDateFromToday, getIsoDate } from '../../../../helpers/datesHelper'
import { actions } from '../../dashboard.module'
import { useDispatch, useSelector } from 'react-redux'
import { getParams } from '../../dashboard.selectors'
import moment from 'moment'
import { getConstantDashboard, getDashboardSettings } from '../../../core/core.selectors'
import { ISettingsCampaign, ISettingsClient, ISettingsPartner } from '../../../core/core.types'
import { ICity, ICountry, IOptionForSelect } from '../../../brief/brief.types'
import { useTranslation } from 'react-i18next'

const selectRoleOptions = [
  { label: 'All', value: 'all' },
  { label: 'Clients', value: 'clients' },
  { label: 'Partners', value: 'partners' },
]

interface IProps {
  onClose: () => void
  chartType: CHART_TYPES | null
}

const DashboardFilter = ({ onClose, chartType }: IProps) => {
  const { Option, OptGroup } = Select

  const {t} = useTranslation(['translationDashboard'])
  const dispatch = useDispatch()
  const settings = useSelector(getDashboardSettings)
  const constants = useSelector(getConstantDashboard)
  const params = useSelector(getParams)

  const [countries, setCountries] = useState<ICountry[]>([])
  const [form] = Form.useForm()

  useEffect(() => {
    if (!settings) return

    !params.countries.length ? setCountries(settings.cities) : setCountries(params.countries)
  }, [settings, params])

  useEffect(() => {
    if (!chartType) return

    form.setFieldsValue({
      date: [moment(params[chartType].before), moment(params[chartType].after)],
      user: params.userRole,
      distTypes: params[chartType].types,
      partners: params[chartType].partnerIds,
      clients: params[chartType].clientIds,
      campaigns: params[chartType].campaignIds,
      industries: params[chartType].industries,
      productTypes: params[chartType].productTypes,
      cities: params[chartType].cityNames,
      countries: params.countries.map((c: ICountry) => c.id),
      segments: params[chartType].segments,
      campaignTypes: params[chartType].campaignTypes,
      serviceTypes: params[chartType].serviceTypes,
      isTaxesIncluded: params[chartType].isTaxesIncluded,
    })
  }, [params])

  const handleDatePicker = (range: any) => {
    if (!range) return
    const start = range && getIsoDate(range[0].startOf('day'))
    const end = range && getIsoDate(range[1].endOf('day'))

    form.setFieldsValue({ date: [moment(start), moment(end)] })
  }

  const getNewParams = (values: any) => {
    const newParams = {
      ...params,
      type: chartType,
      userRole: values.user,
    }

    newParams[chartType!] = {
      before: values.date && values.date[0],
      after: values.date && values.date[1],
      types: values.distTypes,
      partnerIds: values.partners,
      clientIds: values.clients,
      campaignIds: values.campaigns,
      industries: values.industries,
      productTypes: values.productTypes,
      cityNames: values.cities,
      segments: values.segments,
      campaignTypes: values.campaignTypes,
      serviceTypes: values.serviceTypes,
      isTaxesIncluded: values.isTaxesIncluded,
    }

    return newParams
  }
  const onFinish = async (formValues: any) => {
    if (!chartType) return

    const params = getNewParams(formValues)
    await dispatch(actions.setParamsAndFetchChart(params))

    onClose()
  }

  const handlePresetButton = (preset: DATE_PRESET) => {
    const start = getIsoDate(getDateFromToday(preset))
    const end = getIsoDate(moment().endOf('day'))
    form.setFieldsValue({ date: [moment(start), moment(end)] })
  }

  const setCountryFilter = (countries: ICountry[]) => {
    if (!chartType) return

    const formValues = form.getFieldsValue()
    const newParams = getNewParams(formValues)
    newParams.countries = countries

    dispatch(actions.setDashboardParams(newParams))
  }

  const handleCountry = (countiesIds: number[], options: ICountry[]) => {
    const countries = options.map((opt: any) => opt.object)

    setCountryFilter(countries)
    setCountries(countries)
  }

  const handleClear = () => {
    form.resetFields()
    handlePresetButton(DATE_PRESET.MONTH)
    setCountryFilter([])
  }

  const handleSave = () => {
    form.submit()
  }

  const footer = (
    <Row align="middle" justify="space-between">
      <Col>
        <QButton className="qu-button-outline" size="small" onClick={handleClear}>
          {t("dashboard.filters.clear_filters")}
        </QButton>
      </Col>
      <Col>
        <QButton className="w-100" type="primary" size="small" onClick={handleSave}>
        {t("dashboard.save")}
        </QButton>
      </Col>
    </Row>
  )

  const distTypeFilter = [CHART_TYPES.SAMPLE_DISTRIBUTION_ACTIVE, CHART_TYPES.SAMPLE_DISTRIBUTION_COMPLETE]
  const partnerFilter = [
    CHART_TYPES.SAMPLE_DISTRIBUTION_ACTIVE,
    CHART_TYPES.SAMPLE_DISTRIBUTION_COMPLETE,
    CHART_TYPES.BRIEFS,
    CHART_TYPES.CAMPAIGNS,
    CHART_TYPES.TRANSACTIONS,
  ]
  const clientFilter = [
    CHART_TYPES.SAMPLE_DISTRIBUTION_ACTIVE,
    CHART_TYPES.SAMPLE_DISTRIBUTION_COMPLETE,
    CHART_TYPES.REVENUE,
    CHART_TYPES.BRIEFS,
    CHART_TYPES.CAMPAIGNS,
  ]
  const industryFilter = [
    CHART_TYPES.SAMPLE_DISTRIBUTION_ACTIVE,
    CHART_TYPES.SAMPLE_DISTRIBUTION_COMPLETE,
    CHART_TYPES.TRANSACTIONS,
  ]
  const productTypeFilter = [
    CHART_TYPES.SAMPLE_DISTRIBUTION_ACTIVE,
    CHART_TYPES.SAMPLE_DISTRIBUTION_COMPLETE,
    // CHART_TYPES.REVENUE,
    CHART_TYPES.BRIEFS,
  ]
  //TO-DO: Refactor, delete commented filters if not used anywhere else
  // const serviceTypeFilter = [CHART_TYPES.REVENUE]
  // const taxesFilter = [CHART_TYPES.REVENUE]
  const campaignStatusFilter = [CHART_TYPES.REVENUE, CHART_TYPES.CAMPAIGNS]
  const segmentFilter = [CHART_TYPES.TRANSACTIONS]
  const cityFilter = [CHART_TYPES.SAMPLE_DISTRIBUTION_ACTIVE, CHART_TYPES.SAMPLE_DISTRIBUTION_COMPLETE]

  const showUserSelect = chartType === CHART_TYPES.SIGNUP_REQUESTS_CLIENT
  const showCampaignSelect = chartType === CHART_TYPES.CAMPAIGN_COMPLETION
  const showActiveUsersSelect = chartType === CHART_TYPES.ACTIVE_USERS

  const showDistTypeSelect = chartType && distTypeFilter.includes(chartType)
  const showPartnersSelect = chartType && partnerFilter.includes(chartType)
  const showClientsSelect = chartType && clientFilter.includes(chartType)
  const showIndustriesSelect = chartType && industryFilter.includes(chartType)
  const showProductTypeSelect = chartType && productTypeFilter.includes(chartType)
  const showCitiesSelect = chartType && cityFilter.includes(chartType)
  // const showServiceTypesSelect = chartType && serviceTypeFilter.includes(chartType)
  const showCampaignStatusSelect = chartType && campaignStatusFilter.includes(chartType)
  const showSegmentsSelect = chartType && segmentFilter.includes(chartType)
  // const showTaxesSelect = chartType && taxesFilter.includes(chartType)

  const partnersOptions = settings?.partners.map((p: ISettingsPartner) => ({ label: p.user.companyName, value: p.id }))
  const clientsOptions = settings?.clients.map((c: ISettingsClient) => ({ label: c.companyName, value: c.id }))
  const campaignsOptions = settings?.campaigns.map((c: ISettingsCampaign) => ({ label: c.name, value: c.id }))
  const countriesOptions = settings?.cities.map((c: ICountry) => ({ label: c.name, value: c.id, object: c }))

  const optionItem = (item: string) => ({ label: item, value: item })

  const translatedOptionItems = (module:string,elements:any) => {
    return elements.map((item:string)=>{
      return {
        label:t(`dashboard.${module}.${item}`),
        value:item
      }
    })
  }

  const campaignStatusOptions = translatedOptionItems('campaign-status-options',constants?.campaignTypes)
  const distTypesOptions = constants?.sampleTypes.map(optionItem)
  const industriesOptions = translatedOptionItems('industry-options',constants?.industries)
  const productTypesOptions = translatedOptionItems('product-type-options',constants?.productTypes)
  const segmentsOptions = translatedOptionItems('segment-options',constants?.segments)
  const serviceTypesOptions = translatedOptionItems('service-type-options',constants?.serviceTypes)

  const presetButtons = showActiveUsersSelect ? ACTIVE_USER_BUTTON_DATES : BUTTON_DATES

  if (!chartType) return null

  return (
    <Modal
      wrapClassName="qu-modal filters-modal"
      closeIcon={<IconsCommon.IconClose />}
      visible={!!chartType}
      onCancel={onClose}
      title={<h3 className="text-center">{t("dashboard.filters.filter_results")}</h3>}
      footer={footer}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col xs={24}>
            {!showCampaignSelect && (
              <Fragment>
                <Form.Item label={t("dashboard.date")} name="date">
                  <QRangePicker
                    placeholder={[t("dashboard.start_date"),t("dashboard.end_date")]}
                    allowClear={false}
                    format={FORMATS.DATE_FORMAT}
                    onChange={handleDatePicker}
                    className="full-width force-ltr"
                    size="large"
                  />
                </Form.Item>
                <Row align="middle" justify="space-between" className={'mb-10'}>
                  {presetButtons.map((button: DATE_PRESET) => {
                    return (
                      <Col key={button}>
                        <QButton className="qu-button-outline" size="small" onClick={() => handlePresetButton(button)}>
                          {t(`dashboard.filters.${BUTTON_DATES_NAMES[button]}`)}
                        </QButton>
                      </Col>
                    )
                  })}
                </Row>
              </Fragment>
            )}
            {showUserSelect && (
              <Form.Item label={t("dashboard.filters.user")} name="user">
                <QSelect options={selectRoleOptions} placeholder={`${t("dashboard.filters.filter_by")} ${t("dashboard.filters.user")}`} size="large" />
              </Form.Item>
            )}

            {showDistTypeSelect && (
              <Form.Item label="Distribution Types" name="distTypes">
                <QSelect size="large" mode="multiple" placeholder="Filter by Type" options={distTypesOptions} />
              </Form.Item>
            )}

            {showPartnersSelect && (
              <Form.Item label={t("dashboard.filters.partners")} name="partners">
                <QSelect
                  size="large"
                  mode="multiple"
                  placeholder={`${t("dashboard.filters.filter_by")} ${t("dashboard.filters.partner")}`}
                  maxTagCount={3}
                  maxTagTextLength={8}
                  options={partnersOptions}
                  filterOption={(input: string, option: IOptionForSelect) =>
                    option.label.toLowerCase().includes(input.toLocaleLowerCase())
                  }
                />
              </Form.Item>
            )}

            {showIndustriesSelect && (
              <Form.Item label={t("dashboard.filters.industries")} name="industries">
                <QSelect
                  size="large"
                  mode="multiple"
                  placeholder={`${t("dashboard.filters.filter_by")} ${t("dashboard.filters.industry")}`}
                  maxTagCount={3}
                  maxTagTextLength={8}
                  options={industriesOptions}
                />
              </Form.Item>
            )}

            {showClientsSelect && (
              <Form.Item label={t("dashboard.filters.companies")} name="clients">
                <QSelect
                  size="large"
                  mode="multiple"
                  placeholder={`${t("dashboard.filters.filter_by")} ${t("dashboard.filters.company")}`}
                  maxTagCount={3}
                  maxTagTextLength={8}
                  options={clientsOptions}
                  filterOption={(input: string, option: IOptionForSelect) =>
                    option.label.toLowerCase().includes(input.toLocaleLowerCase())
                  }
                />
              </Form.Item>
            )}

            {showProductTypeSelect && (
              <Form.Item label={t("dashboard.filters.product_types")} name="productTypes">
                <QSelect
                  size="large"
                  mode="multiple"
                  placeholder={`${t("dashboard.filters.filter_by")} ${t("dashboard.filters.product_type")}`}
                  maxTagCount={3}
                  maxTagTextLength={8}
                  options={productTypesOptions}
                />
              </Form.Item>
            )}

            {/*{showServiceTypesSelect && (
              <Form.Item label={t("dashboard.filters.service_types")} name="serviceTypes">
                <QSelect
                  size="large"
                  mode="multiple"
                  placeholder={`${t("dashboard.filters.filter_by")} ${t("dashboard.filters.service_type")}`}
                  maxTagCount={3}
                  maxTagTextLength={8}
                  options={serviceTypesOptions}
                />
              </Form.Item>
            )}*/}

            {showCitiesSelect && (
              <Fragment>
                <Form.Item label={t("dashboard.filters.countries")} name="countries">
                  <QSelect
                    showSearch
                    showArrow
                    mode="multiple"
                    size="large"
                    placeholder={`${t("dashboard.filters.filter_by")} ${t("dashboard.filters.country")}`}
                    onChange={handleCountry}
                    options={countriesOptions}
                  />
                </Form.Item>
                <Form.Item label={t("dashboard.filters.cities")} name="cities">
                  <QSelect showSearch showArrow mode="multiple" size="large" placeholder={`${t("dashboard.filters.filter_by")} ${t("dashboard.filters.city")}`}>
                    {countries.map((country: ICountry) => (
                      <OptGroup key={country.id} label={country.name}>
                        {country.city.map((city: ICity) => (
                          <Option key={city.id} country={country.name} value={city.name}>
                            {city.name}
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
                  </QSelect>
                </Form.Item>
              </Fragment>
            )}

            {showCampaignStatusSelect && (
              <Form.Item label={t("dashboard.filters.campaign_status")} name="campaignTypes">
                <QSelect
                  size="large"
                  mode="multiple"
                  placeholder={`${t("dashboard.filters.filter_by")} ${t("dashboard.filters.campaign_status")}`}
                  options={campaignStatusOptions}
                />
              </Form.Item>
            )}

            {showSegmentsSelect && (
              <Form.Item label={t("dashboard.filters.segments")} name="segments">
                <QSelect
                  size="large"
                  mode="multiple"
                  placeholder={`${t("dashboard.filters.filter_by")} ${t("dashboard.filters.segments")}`}
                  maxTagCount={3}
                  maxTagTextLength={8}
                  options={segmentsOptions}
                />
              </Form.Item>
            )}

            {showCampaignSelect && (
              <Form.Item label={t("dashboard.filters.campaigns")} name="campaigns">
                <QSelect
                  size="large"
                  mode="multiple"
                  placeholder={`${t("dashboard.filters.filter_by")} ${t("dashboard.filters.campaign")}`}
                  maxTagCount={3}
                  maxTagTextLength={8}
                  options={campaignsOptions}
                  filterOption={(input: string, option: IOptionForSelect) =>
                    option.label.toLowerCase().includes(input.toLocaleLowerCase())
                  }
                />
              </Form.Item>
            )}
            {/*{showTaxesSelect && (
              <Form.Item label={t("dashboard.filters.include_management_fee")} name="isTaxesIncluded">
                <QSelect
                  allowClear
                  options={[
                    { label: t("dashboard.yes"), value: true },
                    { label: t("dashboard.no"), value: false },
                  ]}
                  placeholder={t("dashboard.please_select")}
                  size="large"
                />
              </Form.Item>
            )}*/}
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
export default DashboardFilter
