import { Empty, IconsCommon, QBarChartSimple, QButton } from 'quantum_components'
import React from 'react'
import { useSelector } from 'react-redux'
import { getParams, getRevenueChartData } from '../../dashboard.selectors'
import { CHART_TYPES } from '../../../../constants/dashboard'
import { hasSomeChartData } from '../../../../helpers/campaign'
import moment from 'moment'
import { FORMATS } from 'src/constants'
import { getLocaleCostWithoutDecimal } from 'src/helpers/currency'
import { getCurrency } from 'src/modules/core/core.selectors'
import { useTranslation } from 'react-i18next'

interface IProps {
  openFilter: (type: CHART_TYPES) => void
}

const RevenueChart = ({ openFilter }: IProps) => {
  const data = useSelector(getRevenueChartData)
  const hasData = hasSomeChartData(data)
  const params = useSelector(getParams)
  const currency = useSelector(getCurrency)
  const {t} = useTranslation(['translationDashboard'])
  return (
    <div className="qu-chart-card mb-24">
      <div className="card-header">
        <div>
          <h3>{t("dashboard.revenue")}</h3>
          <small>
            {moment(params[CHART_TYPES.REVENUE].before).format(FORMATS.DATE_ALPHA_FORMAT)} -{' '}
            {moment(params[CHART_TYPES.REVENUE].after).format(FORMATS.DATE_ALPHA_FORMAT)}
          </small>
          <br />
          <small>
          {t("dashboard.total")}:{' '}
            {getLocaleCostWithoutDecimal(
              data.reduce(function (accumulator, item) {
                return accumulator + item.value
              }, 0)
            )}{' '}
            {currency}
          </small>
        </div>
        <div className="card-header-right">
          <QButton className="qu-button-outline qu-brief-filter-button" onClick={() => openFilter(CHART_TYPES.REVENUE)}>
            <span className="h-24">
              <IconsCommon.FilterIcon />
            </span>
          </QButton>
        </div>
      </div>
      <div className="mv-20 mr-20">
        {!hasData ? (
          <div className={'h-300 child-center'}>
            <Empty description={t("dashboard.empty")} />
          </div>
        ) : (
          <QBarChartSimple className="barchart-height-stabilize h-350" data={data} xAngle={-45} />
        )}
      </div>
    </div>
  )
}

export default RevenueChart
