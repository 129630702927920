/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { QSelect, IconsCommon, QButton, QInput, QPagination, QTable, Row, Form, QCheckbox } from "quantum_components";
import { Col, Modal, Tooltip, Button } from "antd";
import {
  CheckCircleTwoTone,
  EyeTwoTone,
  PlusCircleTwoTone,
  SearchOutlined,
  StopTwoTone,
  CloudUploadOutlined,
  CloudOutlined,
  LockTwoTone,
  CreditCardTwoTone,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import { PATHS, ROLES } from "src/constants";
import { PERMISSIONS } from "src/constants/permission";
import { hasPermission } from "src/helpers/permission";
import { useReduxDispatch } from "src/helpers";
import useDebounce from "src/hooks/useDebounce";
import {
  getCurrency,
  getCurrentUserPermissionActions,
} from "src/modules/core/core.selectors";
import { actions as partnerProfileActions } from "src/modules/partner/partner-Profile/partnerProfile.module";

import { IUserListElement } from "../users.types";
import {
  getPages,
  getTotalUsersList,
  getUsersList,
  getUsersListLoading,
} from "../users.selectors";

import * as actions from "../users.actions";

import { ReactComponent as KeyIcon } from "src/assets/icons/key-icon.svg";
import { useTranslation } from "react-i18next";
import i18n from "src/i18n";
import { validateMessages } from "src/helpers/validationMessage";
import SpinnerCenter from "src/components/Spinner/spinner";
import { isMobile } from "react-device-detect";

enum ACTIONS {
  BAN_USER = "BAN_USER",
  UNBAN_USER = "UNBAN_USER",
  RESTRICT_UPLOAD_PO = "RESTRICT_UPLOAD_PO",
  ALLOW_UPLOAD_PO = "ALLOW_UPLOAD_PO",
  RESTRICT_PAYMENT_STEP = "RESTRICT_PAYMENT_STEP",
  ALLOW_PAYMENT_STEP = "ALLOW_PAYMENT_STEP",
  ACTIVATE_ACCOUNT_STEP = "ACTIVATE_ACCOUNT_STEP",
}

const UsersList = () => {
  const { t } = useTranslation(["translationClient", "translationCommon", "translationPlatform"]);
  const dispatch = useReduxDispatch();
  const history = useHistory();
  const [form] = Form.useForm()

  const usersList = useSelector(getUsersList);
  const usersListTotal = useSelector(getTotalUsersList);
  const usersListIsLoading = useSelector(getUsersListLoading);

  const permissions = useSelector(getCurrentUserPermissionActions);
  const pages = useSelector(getPages);
  const currency = useSelector(getCurrency);

  const usersRole = history.location.pathname.includes("partners")
    ? ROLES.PARTNER
    : ROLES.CLIENT;

  const [params, setParams] = useState({
    currentPage: 1,
    offset: 0,
    limit: 10,
    order: "desc",
    orderBy: "createdAt",
    role: usersRole,
  });

  const [modalBan, setModalBan] = useState({ visible: false, id: null });
  const [modalUnban, setModalUnban] = useState({ visible: false, id: null });
  const [modalRestrict, setModalRestrict] = useState({
    visible: false,
    id: null,
  });
  const [modalAllow, setModalAllow] = useState({ visible: false, id: null });
  const [modalRestrictPayment, setModalRestrictPayment] = useState({
    visible: false,
    id: null,
  });
  const [modalActivateAccount, setModalActivateAccount] = useState({
    visible: false,
    id: null,
  });
  const [modalAllowPayment, setModalAllowPayment] = useState({
    visible: false,
    id: null,
  });

  const [tableKey, setTableKey] = useState(1);
  const [tableKey1, setTableKey1] = useState(100);
  const [tableKey2, setTableKey2] = useState(1000);
  const [tableKey3, setTableKey3] = useState(10000);
  const [tableKey4, setTableKey4] = useState(100000);
  const [tableKey5, setTableKey5] = useState(1000000);
  const [tableKey6, setTableKey6] = useState(10000000);
  const [tableKey7, setTableKey7] = useState(100000000);
  const [tableKey8, setTableKey8] = useState(1000000000);

  const [search, setSearch] = useState("");
  const [updatePasswordModal, setUpdatePasswordModal] = useState<boolean>(
    false
  );
  const [updatePasswordModalData, setUpdatePasswordModalData] = useState<any>(
    {}
  );
  const [paymentMethods, setPaymentMethods] = useState({ visible: false, data: null });

  const [activeFilter, setActiveFilter] = useState<any>();

  const debouncedSearch = useDebounce(search, 500);

  const fetchUsersList = (queryParams = params) => {
    dispatch(
      actions.fetchUsersList({ ...queryParams, search: debouncedSearch })
    );
  };

  useEffect(() => {
    dispatch(actions.resetUsers())
    fetchUsersList();

    return () => dispatch(actions.resetUsers());
  }, [debouncedSearch]);

  const handleOpenModal = (action: ACTIONS, record: IUserListElement) => {
    const MODAL_OPENER_FUNCTIONS_BY_ACTION = {
      [ACTIONS.BAN_USER]: setModalBan,
      [ACTIONS.UNBAN_USER]: setModalUnban,
      [ACTIONS.RESTRICT_UPLOAD_PO]: setModalRestrict,
      [ACTIONS.ALLOW_UPLOAD_PO]: setModalAllow,
      [ACTIONS.ALLOW_PAYMENT_STEP]: setModalAllowPayment,
      [ACTIONS.RESTRICT_PAYMENT_STEP]: setModalRestrictPayment,
      [ACTIONS.ACTIVATE_ACCOUNT_STEP]: setModalActivateAccount,
    };

    const actionHandler = MODAL_OPENER_FUNCTIONS_BY_ACTION[action];
    actionHandler({ visible: true, id: record.id });
  };

  const handleOpenPartnerProfile = (id) => {
    const isPartnersRoute = history.location.pathname.includes("partners");
    dispatch(partnerProfileActions.unMountClearDataPartnerProfile());
    isPartnersRoute
      ? history.push(`partners/partner-profile/${id}`)
      : history.push(`clients/profile/${id}`);
  };

  const handleOpenUserUpdatePassword = async (user) => {
    let generatedPassword = await dispatch(actions.getUserGeneratedPassword());
    if (generatedPassword) {
      setUpdatePasswordModal(true);
      setUpdatePasswordModalData({ user, generatedPassword });
    }
  };

  const sendUpdatedPassword = async () => {
    setUpdatePasswordModal(false);
    setTableKey(tableKey + 1);
    let generatedPasswordResponse = await dispatch(
      actions.sendUserGeneratedPassword({
        userId: updatePasswordModalData.user.id,
        password: updatePasswordModalData.generatedPassword,
      })
    );
    if (generatedPasswordResponse === 201) {
      setUpdatePasswordModalData({});
    }
  };

  const handleCloseModals = () => {
    setModalBan({ visible: false, id: null });
    setModalUnban({ visible: false, id: null });
    setModalRestrict({ visible: false, id: null });
    setModalAllow({ visible: false, id: null });
    setModalAllowPayment({ visible: false, id: null });
    setModalRestrictPayment({ visible: false, id: null });
    setModalActivateAccount({ visible: false, id: null });
    setTableKey1(tableKey1 + 1);
    setTableKey2(tableKey2 + 1);
    setTableKey3(tableKey3 + 1);
    setTableKey4(tableKey4 + 1);
    setTableKey5(tableKey5 + 1);
    setTableKey6(tableKey6 + 1);
    setTableKey8(tableKey8 + 1);
  };

  const handleSubmitModal = async (action: ACTIONS, id: number) => {
    const SUBMIT_HANDLERS_BY_ACTION = {
      [ACTIONS.BAN_USER]: actions.banUser,
      [ACTIONS.UNBAN_USER]: actions.unbanUser,
      [ACTIONS.RESTRICT_UPLOAD_PO]: actions.restrictUploadPo,
      [ACTIONS.ALLOW_UPLOAD_PO]: actions.allowUploadPo,
      [ACTIONS.RESTRICT_PAYMENT_STEP]: actions.restrictPaymentStep,
      [ACTIONS.ALLOW_PAYMENT_STEP]: actions.allowPaymentStep,
      [ACTIONS.ACTIVATE_ACCOUNT_STEP]: actions.activateClientAccount,
    };
    const actionHandler = SUBMIT_HANDLERS_BY_ACTION[action];

    await dispatch(actionHandler(id));
    if (action === ACTIONS.ACTIVATE_ACCOUNT_STEP) fetchUsersList({ ...params, search });
    handleCloseModals();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      order: sorter.order.slice(0, -3),
      orderBy: sorter.columnKey,
    };
    const newParams = {
      ...params,
      ...sortingParams,
    };
    setParams(newParams);
    fetchUsersList(newParams);
  };

  const handleChangeSearch = (e) => {
    setSearch(e.currentTarget.value);
  };
  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page));
    dispatch(
      actions.fetchUsersList({
        ...params,
        offset: (page - 1) * params.limit,
        currentPage: page,
        limit: pageSize,
      })
    );
  };

  const handleAddProperties = (partner: IUserListElement) => {
    dispatch(actions.setCurrentUser(partner));
    history.push(`/users/partners/add-properties/${partner.id}`);
  };

  const columns = [
    {
      title: t(`clients.full_name`),
      dataIndex: "fullname",
      key: "firstName",
      width: "20%",
      sorter: true,
    },
    {
      title: t(`clients.email`),
      dataIndex: "email",
      key: "email",
      width: "20%",
      sorter: false,
    },
    {
      title: t(`clients.username`),
      dataIndex: "username",
      key: "username",
      width: "20%",
      sorter: false,
    },
    {
      title: t(`clients.company_name`),
      dataIndex: "companyName",
      key: "companyName",
      width: "20%",
      sorter: true,
    },
    {
      title: t(`clients.phone_number`),
      dataIndex: "phone",
      key: "phone",
      width: "20%",
      sorter: false,
    },
    {
      title: t(`clients.actions`),
      dataIndex: "actions",
      key: "services",
      width: "20%",
      render: (text, record, id) => {
        const viewProfileAction =
          usersRole === ROLES.PARTNER
            ? PERMISSIONS.PARTNER_VIEW_PROFILE
            : PERMISSIONS.CLIENT_VIEW_PROFILE;
        const suspendAction =
          usersRole === ROLES.PARTNER
            ? PERMISSIONS.PARTNER_SUSPEND
            : PERMISSIONS.CLIENT_SUSPEND;
        const updatePasswordAction =
          usersRole === ROLES.PARTNER
            ? PERMISSIONS.PARTNER_SUSPEND
            : PERMISSIONS.CLIENT_SUSPEND; // change the permission constant to update password new permissions

        if (record?.status === 'INACTIVE') {
          return <>
            {
              hasPermission(permissions, suspendAction) &&
              <QButton htmlType="button" type="primary" size="middle" onClick={() => handleOpenModal(ACTIONS.ACTIVATE_ACCOUNT_STEP, record)}>
                {t(`clients.activate_now`)}
              </QButton>
            }
          </>
        }
        return (
          /*QUA-1449, QP-492 - prevent event bubbling to onRowClick*/
          <div onClick={(e) => e.stopPropagation()}>
            {hasPermission(permissions, viewProfileAction) && (
              <Tooltip title={t(`clients.view_user_profile`)}>
                <EyeTwoTone
                  style={{
                    fontSize: "20px",
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                  onClick={() => handleOpenPartnerProfile(usersList[id].id)}
                />
              </Tooltip>
            )}
            {hasPermission(permissions, updatePasswordAction) && (
              <Tooltip title={t(`clients.update_user_password`)}>
                <KeyIcon
                  style={{
                    fontSize: "20px",
                    marginRight: "5px",
                    marginLeft: "5px",
                    fill: "#1890ff",
                  }}
                  onClick={() => handleOpenUserUpdatePassword(usersList[id])}
                />
              </Tooltip>
            )}
            {hasPermission(permissions, suspendAction) && (
              <React.Fragment>
                {record.isBanned ? (
                  <Tooltip title={t(`clients.unban_user`)}>
                    <CheckCircleTwoTone
                      style={{
                        fontSize: "20px",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                      onClick={() =>
                        handleOpenModal(ACTIONS.UNBAN_USER, record)
                      }
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={t(`clients.ban_user`)}>
                    <StopTwoTone
                      style={{
                        fontSize: "20px",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                      onClick={() => handleOpenModal(ACTIONS.BAN_USER, record)}
                    />
                  </Tooltip>
                )}
              </React.Fragment>
            )}
            {usersRole === ROLES.CLIENT &&
              hasPermission(
                permissions,
                PERMISSIONS.CLIENT_ALLOW_PO_UPLOAD
              ) && (
                <React.Fragment>
                  {record.paymentEnabled ? (
                    <Tooltip title={t(`clients.disable_payment`)}>
                      <LockTwoTone
                        style={{
                          fontSize: "20px",
                          color: "#1890ff",
                          marginRight: "5px",
                          marginLeft: "5px",
                        }}
                        onClick={() =>
                          handleOpenModal(ACTIONS.RESTRICT_PAYMENT_STEP, record)
                        }
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t(`clients.allow_payment`)}>
                      <CreditCardTwoTone
                        style={{
                          fontSize: "20px",
                          color: "#1890ff",
                          marginRight: "5px",
                          marginLeft: "5px",
                        }}
                        onClick={() =>
                          handleOpenModal(ACTIONS.ALLOW_PAYMENT_STEP, record)
                        }
                      />
                    </Tooltip>
                  )}
                </React.Fragment>
              )}
            {usersRole === ROLES.CLIENT &&
              hasPermission(
                permissions,
                PERMISSIONS.CLIENT_ALLOW_PO_UPLOAD
              ) && (
                <React.Fragment>
                  <Tooltip title={t(`clients.payment_methods`)}>
                    <CreditCardTwoTone
                      style={{
                        fontSize: "20px",
                        color: "#1890ff",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                      onClick={() => {
                        setPaymentMethods({ visible: true, data: record })
                        form.setFieldsValue({
                          bankTransfer: record?.bankTransfer,
                          purchaseOrder: record?.purchaseOrder,
                          onlinePayment: record?.onlinePayment
                        })
                      }}
                    />
                  </Tooltip>
                </React.Fragment>
              )}
          </div>
        );
      },
    },
  ];

  if (columns.length && usersRole === ROLES.PARTNER) {
    if (!columns.find((ele) => ele.key === "pricePerSample")) {
      columns.splice(columns.findIndex((ele) => ele.key === "phone") + 1, 0, {
        title: `Sample price ${currency}`,
        dataIndex: "pricePerSample",
        key: "pricePerSample",
        fixed: "left",
        sorter: true,
        render: (text: any) => <span>{Number(text) ? text : "-"}</span>,
      });
    }
  }

  const handleOnRowClick = (user: IUserListElement) => ({
    onClick: (event: any) => {
      if (user.status === 'INACTIVE') return;

      const isPartnersRoute = history.location.pathname.includes("partners");
      isPartnersRoute
        ? history.push(`partners/partner-profile/${user.key}`)
        : history.push(`clients/profile/${user.key}`);
    },
  });

  const onPaymentMethodModalCancel = async () => {
    setPaymentMethods({ visible: false, data: null })
    form.resetFields()
    setTableKey7(tableKey7 + 1)
  }

  const onCheck = async () => {
    try {
      const values = await form.validateFields()
      if (values.bankTransfer || values.purchaseOrder || values.onlinePayment) {
        await dispatch(actions.allowPaymentMethods(paymentMethods.data.id, values))
        onPaymentMethodModalCancel()
      }
    } catch (error) {
      return error
    }
  }

  const filterOptions = [
    {
      label: t(`menu.pending_clients`, { ns: "translationPlatform" }),
      value: 'PENDING'
    }
  ];

  const handleFilter = (activeFilter: string) => {
    setActiveFilter(activeFilter)
    if (activeFilter === "PENDING") {
      const newParams = {
        ...params,
        isApproved: false
      }
      setParams(newParams);
      fetchUsersList(newParams);
    }
    // const shouldFilterByApproved = [
    //   FILTERS_BY_USERS.USER_APPROVED,
    //   FILTERS_BY_USERS.USER_NOT_APPROVED,
    // ].includes(activeFilter);
    // if (shouldFilterByApproved) {
    //   const isApproved = activeFilter === FILTERS_BY_USERS.USER_APPROVED;
    //   dispatch(actions.setParamsAndFetch({ ...params, offset: 0, isApproved }));
    //   return;
    // }

    // dispatch(
    //   actions.setParamsAndFetch(omit({ ...params, offset: 0 }, "isApproved"))
    // );
  };

  const handleClearFilter = () => {
    setActiveFilter();
    const newParams = {
      ...params,
      isApproved: undefined
    }
    setParams(newParams);
    fetchUsersList(newParams);
  };

  return (
    <div className="qu-brief-list pb-20">
      {usersListIsLoading && <SpinnerCenter />}
      <Row className={`pt-10 brief-list-header`} justify="space-between">
        <h2 className="pb-10">{t(`clients.title`)}</h2>
      </Row>
      {/*search and create button*/}
      <Row justify="space-between">
        <Col className="qt-search">
          <QInput
            value={search}
            onChange={handleChangeSearch}
            placeholder={t(`clients.search`)}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col className="qt-filter clients-list-filter">
          <QSelect
            onChange={handleFilter}
            value={activeFilter}
            size="large"
            options={filterOptions}
            placeholder={t(`clients.filters`)}
            style={{ width: `90% !important` }}
          />
          &nbsp;{activeFilter && <CloseCircleOutlined onClick={handleClearFilter} />}
        </Col>
        <Col>
          <Row justify="end">
            <Col>
              <h5 className="pt-8 pr-10">
                {activeFilter ? t(`clients.total_pending_clients`, {
                  total: usersListTotal,
                }) : t(`clients.total_available_clients`, {
                  total: usersListTotal,
                })}
              </h5>
            </Col>
            <Col>
              <QButton htmlType="button" type="primary" onClick={() => history.push(`/users/clients/create`)}>
                <span className="inlineTextIcon">+</span>&nbsp;{t(`menu.add_client`, { ns: 'translationPlatform' })}
              </QButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="pt-15 pb-30">
        <QTable
          //@ts-ignore
          onRow={handleOnRowClick}
          columns={columns}
          dataSource={usersList}
          scroll={{ x: 990 }}
          // loading={usersListIsLoading}
          onChange={handleTableChange}
          sortDirections={["ascend", "descend", "ascend"]}
          className="cursorPointer"
        />
      </div>

      <Modal
        className={i18n.dir() === "rtl" ? "html-rtl" : ""}
        key={tableKey}
        title={<h3>{t(`clients.update_user_password`)}</h3>}
        visible={updatePasswordModal}
        okText={t(`clients.ok`)}
        cancelText={t(`clients.cancel`)}
        onCancel={() => {
          setUpdatePasswordModal(false);
          setUpdatePasswordModalData({});
          setTableKey(tableKey + 1);
        }}
        footer={
          <>
            <Button
              key="back"
              onClick={() => {
                setUpdatePasswordModal(false);
                setUpdatePasswordModalData({});
                setTableKey(tableKey + 1);
              }}
            >
              {t(`clients.cancel`)}
            </Button>
            <Button key="submit" type="primary" onClick={sendUpdatedPassword}>
              {t(`clients.confirm`)}
            </Button>
          </>
        }
      >
        <div>
          <p className="mb-15">
            {t(`clients.password_update_confirmation`, {
              email: updatePasswordModalData?.user?.email,
            })}
          </p>
          <p className="mb-15">
            {t(`clients.generated_password`, {
              password: updatePasswordModalData?.generatedPassword,
            })}
          </p>
          <p>{t(`clients.password_update_help_text`)}</p>
        </div>
      </Modal>

      <Modal
        className={i18n.dir() === "rtl" ? "html-rtl" : ""}
        key={tableKey1}
        title={t(`clients.are_you_sure`)}
        okText={t(`clients.ok`)}
        cancelText={t(`clients.cancel`)}
        visible={modalBan.visible}
        onOk={() => handleSubmitModal(ACTIONS.BAN_USER, modalBan.id)}
        onCancel={() => {
          setModalBan({ visible: false, id: null });
          setTableKey1(tableKey1 + 1);
        }}
      >
        <p>{t(`clients.ban_confirm`)}</p>
      </Modal>

      <Modal
        className={i18n.dir() === "rtl" ? "html-rtl" : ""}
        key={tableKey2}
        title={t(`clients.are_you_sure`)}
        okText={t(`clients.ok`)}
        cancelText={t(`clients.cancel`)}
        visible={modalUnban.visible}
        onOk={() => handleSubmitModal(ACTIONS.UNBAN_USER, modalUnban.id)}
        onCancel={() => {
          setModalUnban({ visible: false, id: null });
          setTableKey2(tableKey2 + 1);
        }}
      >
        <p>{t(`clients.unban_confirm`)}</p>
      </Modal>

      <Modal
        className={i18n.dir() === "rtl" ? "html-rtl" : ""}
        key={tableKey5}
        title={t(`clients.are_you_sure`)}
        visible={modalAllowPayment.visible}
        okText={t(`clients.ok`)}
        cancelText={t(`clients.cancel`)}
        onOk={() =>
          handleSubmitModal(ACTIONS.ALLOW_PAYMENT_STEP, modalAllowPayment.id)
        }
        onCancel={() => {
          setModalAllowPayment({ visible: false, id: null });
          setTableKey5(tableKey5 + 1);
        }}
      >
        <p>{t(`clients.payment_confirm`)}</p>
      </Modal>

      <Modal
        className={i18n.dir() === "rtl" ? "html-rtl" : ""}
        key={tableKey6}
        title={t(`clients.are_you_sure`)}
        okText={t(`clients.ok`)}
        cancelText={t(`clients.cancel`)}
        visible={modalRestrictPayment.visible}
        onOk={() =>
          handleSubmitModal(
            ACTIONS.RESTRICT_PAYMENT_STEP,
            modalRestrictPayment.id
          )
        }
        onCancel={() => {
          setModalRestrictPayment({ visible: false, id: null });
          setTableKey6(tableKey6 + 1);
        }}
      >
        <p>{t(`clients.non_payment_confirm`)}</p>
      </Modal>

      <Modal
        className={i18n.dir() === "rtl" ? "html-rtl" : ""}
        key={tableKey3}
        title={t(`clients.are_you_sure`)}
        okText={t(`clients.ok`)}
        cancelText={t(`clients.cancel`)}
        visible={modalRestrict.visible}
        onOk={() =>
          handleSubmitModal(ACTIONS.RESTRICT_UPLOAD_PO, modalRestrict.id)
        }
        onCancel={() => {
          setModalRestrict({ visible: false, id: null });
          setTableKey3(tableKey3 + 1);
        }}
      >
        <p>{t(`clients.non_po_confirm`)}</p>
      </Modal>

      <Modal
        className={i18n.dir() === "rtl" ? "html-rtl" : ""}
        key={tableKey4}
        okText={t(`clients.ok`)}
        cancelText={t(`clients.cancel`)}
        title={t(`clients.are_you_sure`)}
        visible={modalAllow.visible}
        onOk={() => handleSubmitModal(ACTIONS.ALLOW_UPLOAD_PO, modalAllow.id)}
        onCancel={() => {
          setModalAllow({ visible: false, id: null });
          setTableKey4(tableKey4 + 1);
        }}
      >
        <p>{t(`clients.po_confirm`)}</p>
      </Modal>

      <Modal
        className={i18n.dir() === "rtl" ? "html-rtl" : ""}
        key={tableKey7}
        closeIcon={<IconsCommon.IconClose />}
        visible={paymentMethods.visible}
        onCancel={onPaymentMethodModalCancel}
        width={400}
        title={<h3>{t('clients.payment_methods')}</h3>}
        onOk={onCheck}
        footer={null}
      >
        <Form
          layout="vertical"
          form={form}
          validateMessages={validateMessages}
          onFinish={onCheck}
        >
          <Row>
            <Col span={24}>
              <Form.Item className="payment-method-checkbox" name={'bankTransfer'} valuePropName="checked">
                <QCheckbox checked={form.bankTransfer} className="test-class"                >
                  {t(`clients.bankTransfer`)}
                </QCheckbox>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item className="payment-method-checkbox" name={'purchaseOrder'} valuePropName="checked">
                <QCheckbox checked={form.purchaseOrder} className="test-class"                >
                  {t(`clients.purchaseOrder`)}
                </QCheckbox>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item className="payment-method-checkbox" name={'onlinePayment'} valuePropName="checked" extra={t('clients.please_select_atleast_one_payment_method')}>
                <QCheckbox checked={form.onlinePayment} className="test-class"                >
                  {t(`clients.onlinePayment`)}
                </QCheckbox>
              </Form.Item>
            </Col>
          </Row>

          <div className="grid-row grid-row--jce create-update-button">
            <div className="grid-col--auto">
              <QButton type="primary" block htmlType="submit">
                {t('common.Update', { ns: "translationCommon" })}
              </QButton>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        className={i18n.dir() === "rtl" ? "html-rtl" : ""}
        key={tableKey8}
        title={t(`clients.are_you_sure`)}
        okText={t(`clients.ok`)}
        cancelText={t(`clients.cancel`)}
        visible={modalActivateAccount.visible}
        onOk={() =>
          handleSubmitModal(
            ACTIONS.ACTIVATE_ACCOUNT_STEP,
            modalActivateAccount.id
          )
        }
        onCancel={() => {
          setModalActivateAccount({ visible: false, id: null });
          setTableKey8(tableKey8 + 1);
        }}
      >
        <p>{t(`clients.account_activate`)}</p>
      </Modal>

      <Row
        justify={`${isMobile ? "center" : "end"}`}
        className={`${isMobile && "pt-15 mb-30"}`}
      >
        <QPagination
          className="force-ltr"
          size="default"
          defaultCurrent={1}
          hideOnSinglePage
          current={pages.currentPage}
          total={usersListTotal}
          onChange={handleChangePage}
        />
      </Row>
    </div>
  );
};

export default UsersList;
