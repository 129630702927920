import { Empty, IconsCommon, QAreaChart, QButton } from 'quantum_components'
import React from 'react'
import { useSelector } from 'react-redux'
import { getClientsChartData, getParams } from '../../dashboard.selectors'
import { CHART_TYPES } from '../../../../constants/dashboard'
import { hasSomeChartData } from '../../../../helpers/campaign'
import moment from 'moment'
import { FORMATS } from 'src/constants'
import { getLocaleCostWithoutDecimal } from 'src/helpers/currency'
import { useTranslation } from 'react-i18next'

interface IProps {
  openFilter: (type: CHART_TYPES) => void
}

const ClientsChart = ({ openFilter }: IProps) => {
  const data = useSelector(getClientsChartData)
  const hasData = hasSomeChartData(data)
  const params = useSelector(getParams)
  const { t } = useTranslation(['translationDashboard'])

  return (
    <div className="qu-chart-card mb-24">
      <div className="card-header">
        <div>
          <h3>{t("dashboard.total_clients")}</h3>
          <small>
            {moment(params[CHART_TYPES.APPROVED_CLIENTS].before).format(FORMATS.DATE_ALPHA_FORMAT)} -{' '}
            {moment(params[CHART_TYPES.APPROVED_CLIENTS].after).format(FORMATS.DATE_ALPHA_FORMAT)}
          </small>
          <br />
          <small>
          {t("dashboard.total")}:{' '}
            {getLocaleCostWithoutDecimal(
              data.reduce(function (accumulator, item) {
                return accumulator + item.key
              }, 0)
            )}
          </small>
        </div>
        <div className="card-header-right">
          <QButton
            className="qu-button-outline qu-brief-filter-button"
            onClick={() => openFilter(CHART_TYPES.APPROVED_CLIENTS)}
          >
            <span className="h-24">
              <IconsCommon.FilterIcon />
            </span>
          </QButton>
        </div>
      </div>
      <div className="mv-20">
        {!hasData ? (
          <div className={'h-300 child-center'}>
            <Empty description={t("dashboard.empty")} />
          </div>
        ) : (
          <QAreaChart label="Clients" className="barchart-height-stabilize h-350" data={data} color="#B489C5" height={300} />
        )}
      </div>
    </div>
  )
}

export default ClientsChart
