import { Col, Empty, Form, IconsCommon, QBarChartSimple, QButton, QPieChart, Row } from 'quantum_components'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getCampaignChartData, getConvertedCampaignsChartData, getParams } from '../../dashboard.selectors'
import { CHART_TYPES } from '../../../../constants/dashboard'
import { hasSomeChartData } from '../../../../helpers/campaign'
import moment from 'moment'
import { FORMATS } from 'src/constants'
import { getLocaleCostWithoutDecimal } from 'src/helpers/currency'
import { useTranslation } from 'react-i18next'

interface IProps {
  openFilter: (type: CHART_TYPES) => void
}

const CampaignChart = ({ openFilter }: IProps) => {
  const {t} = useTranslation(['translationDashboard'])
  const data = useSelector(getCampaignChartData)
  const convertedCampaigns = useSelector(getConvertedCampaignsChartData).map((item:any)=>{
    return {
      name:t(`dashboard.campaigns-and-conversion-labels.${item.name}`),
      value:item.value
    }
  })
  const params = useSelector(getParams)
  

  const hasData = hasSomeChartData(data)
  const hasConverted = hasSomeChartData(convertedCampaigns)

  

  return (
    <div className="qu-chart-card mb-24">
      <div className="card-header">
        <div>
          <h3>{t("dashboard.campaigns_and_conversion")}</h3>
          <small>
            {moment(params[CHART_TYPES.CAMPAIGNS].before).format(FORMATS.DATE_ALPHA_FORMAT)} -{' '}
            {moment(params[CHART_TYPES.CAMPAIGNS].after).format(FORMATS.DATE_ALPHA_FORMAT)}
          </small>
          <br />
          <small>
          {t("dashboard.total")} {t("dashboard.briefs")}: {getLocaleCostWithoutDecimal(convertedCampaigns.length > 0 ? convertedCampaigns[0].value : 0)}
          </small>
          <br />
          <small>
          {t("dashboard.total_converted_to_campaigns")}:{' '}
            {getLocaleCostWithoutDecimal(convertedCampaigns.length > 1 ? convertedCampaigns[1].value : 0)}
          </small>
        </div>

        <div className="card-header-right">
          <QButton
            className="qu-button-outline qu-brief-filter-button"
            onClick={() => openFilter(CHART_TYPES.CAMPAIGNS)}
          >
            <span className="h-24">
              <IconsCommon.FilterIcon />
            </span>
          </QButton>
        </div>
      </div>
      <Form className="card-body">
        <Row gutter={16}>
          <Col span={16}>
            {!hasData ? (
              <div className={'h-200'}>
                <Empty description={t("dashboard.empty")} />
              </div>
            ) : (
              <QBarChartSimple className="barchart-height-stabilize h-350" data={data} xAngle={-45} />
            )}
          </Col>
          <Col span={8}>
            {!hasConverted ? (
              <div className={'h-200'}>
                <Empty description={t("dashboard.empty")} />
              </div>
            ) : (
              <QPieChart data={convertedCampaigns} className={'force-ltr'} />
            )}
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default CampaignChart
