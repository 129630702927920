import { SignInContainer } from '../modules/registration/signIn'
import { ResetPasswordContainer } from '../modules/registration/resetPassword'
import { RestorePasswordContainer } from '../modules/registration/restorePassword'
import { UsersListContainer } from '../modules/users'
import { RequestsListContainer } from '../modules/signUpRequests'
import { InvitedClientsListContainer } from '../modules/invitedClients/clientsRequests'
import { DraftBriefsListContainer } from '../modules/invitedClients/InvitedBriefs'
import { GUARD_META, PATHS } from '../constants'
import ProfilesPage from 'src/modules/partner/partnerProfiles/ProfilesPage'
import { PartnerAddPropertiesContainer } from '../modules/users/users.module'
import { UpdatePasswordContainer } from 'src/modules/registration/updatePassword'
import ProfilePage from 'src/modules/client/client-Profile/containers/Layout/ProfilePage'
import { EmployeesContainer } from 'src/modules/employees'
import { RolesListContainer, RoleEditContainer } from 'src/modules/roles'
import { ActivitiesContainer } from 'src/modules/activities'
import { AgeGroupsContainer } from 'src/modules/ageGroups'
import { SegmentsContainer } from 'src/modules/segments'
import Billings from 'src/modules/bills/containers/Billings.container'
import {
  BriefEditContainer,
  BriefListContainer,
  BriefWrapperContainer,
  BriefViewContainer,
  BriefRejectedContainer,
  BriefRequestsListContainer,
} from 'src/modules/brief'
import RequestDetailsContainer from '../modules/partner/requests/containers/RequestDetails.container'
import { CampaignListContainer } from '../modules/campaign'
import { CampaignDetailsContainer, CampaignEditContainer } from '../modules/campaign/campaign.module'
import { PERMISSIONS } from '../constants/permission'
import { ReferralContainer } from '../modules/referral'
import { DiscountCodesContainer } from 'src/modules/discountCodes'
import { SupportTicketsContainer } from 'src/modules/supportTickets'
import { LeadsContainer } from 'src/modules/leads'
import { AdminSettingsContainer } from 'src/modules/adminSettings'
import DashboardContainer from '../modules/dashboard/containers/Dashboard.container'
// import {
//   TestahelBriefCreateContainer,
//   TestahelBriefEditContainer,
//   TestahelBriefViewContainer,
// } from 'src/modules/testahelBox/testahelBox.module'
import Marketplace from 'src/modules/client/marketplace/containers/Marketplace.container'
import { CustomServicePartnerContainer, ServicePartnerContainer } from 'src/modules/partner/partner-Profile/partnerProfile.module'
import { CreateClientContainer } from 'src/modules/client/create-client'
import { InvitingClientContainer } from 'src/modules/invitingClient'
import { CalendarContainer } from 'src/modules/calendar'
import { PromotionsContainer } from 'src/modules/promotions/promotions.module'
import { WorkflowContainer } from 'src/modules/workflow'
import { PrivacyPolicyContainer } from 'src/modules/privacyPolicy'
import { DisclaimerContainer } from 'src/modules/disclaimer'
import { ReleaseNotesContainer } from 'src/modules/releaseNotes'
import { SubscriptionHistoryContainer } from 'src/modules/subscriptionHistory'
import { WalletContainer } from 'src/modules/wallet'
import { TargetingKeysContainer } from 'src/modules/targetingKeys'
import { NativeTemplatesContainer } from 'src/modules/nativeTemplates'
import { BriefEditV3Container } from 'src/modules/briefV3'
import { CampaignDetailsV3Container } from 'src/modules/campaignV3/campaignV3.module'
import { ServicesV2Container } from 'src/modules/servicesV2'
import ProductsContainer from 'src/modules/products/containers/Products.container'
import { DashboardV2Container } from 'src/modules/dashboardV2'
import PublishersContainer from 'src/modules/publishers/containers/Publishers.container'

export default () => [
  {
    path: PATHS.ROOT,
    exact: true,
    // component: DashboardContainer,
    component: DashboardV2Container,
    loading: 'Custom loading for home page...',
    error: 'Custom error for home page',
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
    },
  },
  {
    path: PATHS.SIGN_IN,
    exact: true,
    component: SignInContainer,
  },
  {
    path: PATHS.RESET_PASSWORD,
    exact: true,
    component: ResetPasswordContainer,
  },
  {
    path: PATHS.RESTORE_PASSWORD,
    exact: true,
    component: RestorePasswordContainer,
  },

  {
    path: PATHS.REQUESTS_PARTNERS,
    exact: true,
    component: RequestsListContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.SIGN_UP_REQUESTS,
    },
  },
  {
    path: PATHS.REQUESTS_CLIENTS,
    exact: true,
    component: RequestsListContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.SIGN_UP_REQUESTS,
    },
  },
  /*{
    path: PATHS.PARTNERS,
    exact: true,
    component: UsersListContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.PARTNER_VIEW,
    },
  },*/

  // {
  //   path: PATHS.SERVICES,
  //   exact: true,
  //   component: ServicePartnerContainer,
  //   meta: {
  //     [GUARD_META.AUTH_ONLY]: true,
  //     [GUARD_META.PERMISSION]: PERMISSIONS.MANAGE_SERVICES,
  //   },
  // },
  {
    path: PATHS.SERVICES,
    exact: true,
    component: ServicesV2Container,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.MANAGE_SERVICES,
    },
  },
  {
    path: PATHS.MANAGE_SERVICES,
    exact: true,
    component: CustomServicePartnerContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.VIEW_CUSTOM_MEDIA,
    },
  },
  {
    path: PATHS.CALENDAR,
    exact: true,
    component: CalendarContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.MANAGE_CALENDAR,
    },
  },
  // {
  //   path: PATHS.PROMOTIONS,
  //   exact: true,
  //   component: PromotionsContainer,
  //   meta: {
  //     [GUARD_META.AUTH_ONLY]: true,
  //     [GUARD_META.PERMISSION]: PERMISSIONS.PROMOTION_VIEW,
  //   },
  // },
  {
    path: PATHS.WORKFLOW,
    exact: true,
    component: WorkflowContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.MANAGE_WORKFLOW,
    },
  },
  {
    path: PATHS.CLIENTS,
    exact: true,
    component: UsersListContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.CLIENT_VIEW,
    },
  },
  {
    path: PATHS.CREATE_CLIENT,
    exact: true,
    component: CreateClientContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.CLIENT_VIEW,
    },
  },
  {
    path: PATHS.INVITE_CLIENT,
    exact: true,
    component: InvitingClientContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.INVITE_CLIENT,
    },
  },
  {
    path: PATHS.PARTNER_PROFILES,
    exact: true,
    component: ProfilesPage,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.PARTNER_VIEW_PROFILE,
    },
  },
  /*{
    path: PATHS.ADD_PARTNER_PROPERTIES,
    exact: true,
    component: PartnerAddPropertiesContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.PARTNER_UPDATE_PROPS,
    },
  },*/
  {
    path: PATHS.UPDATE_PASSWORD,
    exact: true,
    component: UpdatePasswordContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.CHANGE_PASSWORD,
    },
  },
  {
    path: PATHS.CLIENT_PROFILES,
    exact: true,
    component: ProfilePage,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.CLIENT_VIEW_PROFILE,
    },
  },
  {
    path: PATHS.AGE_GROUPS,
    exact: true,
    component: AgeGroupsContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.DEMOGRAPHICS,
    },
  },
  {
    path: PATHS.SEGMENTS,
    exact: true,
    component: SegmentsContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.DEMOGRAPHICS,
    },
  },
  {
    path: PATHS.EMPLOYEES,
    exact: true,
    component: EmployeesContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.EMPLOYEES_ALL,
    },
  },
  {
    path: PATHS.ROLES,
    exact: true,
    component: RolesListContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.ROLES_AND_PERMISSIONS,
    },
  },
  {
    path: PATHS.ROLE_CREATE,
    exact: true,
    component: RoleEditContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.ROLE_CREATE,
    },
  },
  {
    path: PATHS.ROLE_EDIT,
    exact: true,
    component: RoleEditContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.ROLES_AND_PERMISSIONS,
    },
  },
  {
    path: PATHS.ACTIVITIES,
    exact: true,
    component: ActivitiesContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.ACTIVITIES,
    },
  },
  {
    path: PATHS.BRIEF,
    exact: true,
    component: BriefListContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.BRIEFS_ALL,
    },
  },
  {
    path: PATHS.CREATE_BRIEF,
    exact: true,
    component: BriefWrapperContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.BRIEF_CREATE_AND_UPDATE,
    },
  },
  {
    path: PATHS.BRIEF_VIEW,
    exact: true,
    component: BriefViewContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.BRIEF_VIEW,
    },
  },
  {
    path: PATHS.BRIEF_EDIT,
    exact: true,
    component: BriefEditContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.BRIEF_CREATE_AND_UPDATE,
    },
  },
  {
    path: PATHS.BRIEF_EDIT_V3,
    exact: true,
    component: BriefEditV3Container,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.BRIEF_CREATE_AND_UPDATE,
    },
  },
  {
    path: PATHS.BRIEF_REJECTED,
    exact: true,
    component: BriefRejectedContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.BRIEF_CREATE_AND_UPDATE,
    },
  },
  // {
  //   path: PATHS.TESTAHEL_BRIEF_VIEW,
  //   exact: true,
  //   component: TestahelBriefViewContainer,
  //   meta: {
  //     [GUARD_META.AUTH_ONLY]: true,
  //   },
  // },
  // {
  //   path: PATHS.TESTAHEL_BRIEF_CREATE,
  //   exact: true,
  //   component: TestahelBriefCreateContainer,
  //   meta: {
  //     [GUARD_META.AUTH_ONLY]: true,
  //     [GUARD_META.CLIENT_ONLY]: true,
  //   },
  // },
  // {
  //   path: PATHS.TESTAHEL_BRIEF_EDIT,
  //   exact: true,
  //   component: TestahelBriefEditContainer,
  //   meta: {
  //     [GUARD_META.AUTH_ONLY]: true,
  //     [GUARD_META.CLIENT_ONLY]: true,
  //   },
  // },
  {
    path: PATHS.CAMPAIGNS,
    exact: true,
    component: CampaignListContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.CAMPAIGNS_ALL,
    },
  },
  // {
  //   path: PATHS.COMPLETED_CAMPAIGNS,
  //   exact: true,
  //   component: CampaignListContainer,
  //   meta: {
  //     [GUARD_META.AUTH_ONLY]: true,
  //     [GUARD_META.PERMISSION]: PERMISSIONS.CAMPAIGNS_ALL,
  //   },
  // },
  {
    path: PATHS.CAMPAIGN_DETAILS,
    exact: true,
    component: CampaignDetailsContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.CAMPAIGN_VIEW,
    },
  },
  {
    path: PATHS.CAMPAIGN_DETAILS_V3,
    exact: true,
    component: CampaignDetailsV3Container,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.CAMPAIGN_VIEW,
    },
  },
  {
    path: PATHS.CAMPAIGN_EDIT,
    exact: true,
    component: CampaignEditContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.CAMPAIGN_EDIT,
    },
  },
  {
    path: PATHS.REQUESTS_ALL,
    exact: true,
    component: BriefRequestsListContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.REQUEST_ALL,
    },
  },
  {
    path: PATHS.REQUEST_DETAILS,
    exact: true,
    component: RequestDetailsContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.REQUEST_DETAILS,
    },
  },
  {
    path: PATHS.REFERRALS,
    exact: true,
    component: ReferralContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.VIEW_REFERRAL_PROGRAM,
    },
  },
  // {
  //   path: PATHS.DISCOUNT_CODES,
  //   exact: true,
  //   component: DiscountCodesContainer,
  //   meta: {
  //     [GUARD_META.AUTH_ONLY]: true,
  //     [GUARD_META.PERMISSION]: PERMISSIONS.DISCOUNT_CODES,
  //   },
  // },
  {
    path: PATHS.SUBSCRIPTION_HISTORY,
    exact: true,
    component: SubscriptionHistoryContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.VIEW_SUBSCRIPTION_HISTORY,
    },
  },
  {
    path: PATHS.WALLET,
    exact: true,
    component: WalletContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.WALLET_MANAGEMENT,
    },
  },
  {
    path: PATHS.SUPPORT_TICKETS,
    exact: true,
    component: SupportTicketsContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.SUPPORT_TICKETS_MODULE_ACCESS,
    },
  },
  {
    path: PATHS.SUPPORT_TICKETS_CHAT_ID,
    exact: true,
    component: SupportTicketsContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.SUPPORT_TICKETS_MODULE_ACCESS,
    },
  },
  {
    path: PATHS.SUPPORT_TICKETS_ID,
    exact: true,
    component: SupportTicketsContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.SUPPORT_TICKETS_MODULE_ACCESS,
    },
  },
  {
    path: PATHS.LEADS,
    exact: true,
    component: LeadsContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.LEADS,
    },
  },
  {
    path: PATHS.BILLINGS,
    exact: true,
    component: Billings,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.BILLS_ALL,
    },
  },
  {
    path: PATHS.NATIVE_TEMPLATES,
    exact: true,
    component: NativeTemplatesContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.BRIEF_CREATE_AND_UPDATE,
    },
  },
  {
    path: PATHS.TARGETING_KEYS,
    exact: true,
    component: TargetingKeysContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.VIEW_TARGETING_KEYS,
    },
  },
  {
    path: PATHS.ADMIN_SETTINGS,
    exact: true,
    component: AdminSettingsContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.ADMIN_SETTINGS,
    },
  },
  {
    path: PATHS.INVITED_SIGNUPS,
    exact: true,
    component: InvitedClientsListContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.VIEW_INVITED_REQUEST_LISTING,
    },
  },
  {
    path: PATHS.INVITED_CLIENTS_BRIEFS,
    exact: true,
    component: DraftBriefsListContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.VIEW_EXCLUSIVE_BRIEFS_LISTINGS,
    },
  },
  {
    path: PATHS.INVITED_CLIENTS_BRIEFS_VIEW,
    exact: true,
    component: BriefViewContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.VIEW_EXCLUSIVE_BRIEF,
    },
  },
  {
    path: PATHS.INVITED_CLIENTS_BRIEFS_EDIT,
    exact: true,
    component: BriefEditContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.CREATE_AND_EDIT_EXCLUSIVE_BRIEF,
    },
  },
  /*{
    path: PATHS.MARKETPLACE,
    exact: true,
    component: Marketplace,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.SIGN_UP_REQUESTS,
    },
  },*/
  {
    path: PATHS.PRIVACY_POLICY,
    exact: true,
    component: PrivacyPolicyContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.VIEW_PRIVACY_POLICY,
    },
  },
  {
    path: PATHS.DISCLAIMER,
    exact: true,
    component: DisclaimerContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.VIEW_DISCLAIMER,
    },
  },
  {
    path: PATHS.RELEASE_NOTES,
    exact: true,
    component: ReleaseNotesContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.VIEW_RELEASE_NOTES,
    },
  },
  {
    path: PATHS.PRODUCTS,
    exact: true,
    component: ProductsContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.PRODUCTS_MANAGEMENT,
    },
  },
  {
    path: PATHS.PUBLISHERS,
    exact: true,
    component: PublishersContainer,
    meta: {
      [GUARD_META.AUTH_ONLY]: true,
      [GUARD_META.PERMISSION]: PERMISSIONS.VIEW_PUBLISHERS_LIST,
    },
  },
  {
    path: '*',
    // component: DashboardContainer,
    component: DashboardContainer,
    ignoreGlobal: true,
  },
]
