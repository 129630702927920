import { Col, Divider, Row, Tag, Button, Upload } from "antd";
import { AgeGroupCard } from "src/modules/brief/containers/components/Brief.AgeGroupCard";
import lodash from "lodash";
import React, { useEffect, useState } from "react";
import SpinnerCenter from "src/components/Spinner/spinner";
import { IconsCommon, Modal, QButton, QInput } from "quantum_components";
import { renderRoundedNumber } from "src/helpers/currency";
import { CURRENCY } from "src/constants/currencies";
import { Radio } from "quantum_components";
import {
  acceptBriefPayment,
  downloadProtectedMedia,
  overridePaymentByAdmin,
  rejectBriefPayment,
  removeBriefPaymentFile,
  updateCartServicesStatus,
  uploadBriefPaymentFile,
} from "../briefv3.actions";
import { getBriefQuotationPdf } from "src/utils/getScreenPage";
import V3UploadComponent from "./V3Upload.component";
import { getFileNameFromUrl } from "src/utils";
import { isUploadFile } from "src/type-guards";
import { DeleteOutlined, EyeOutlined, CheckOutlined,CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import { FORMATS } from "src/constants";
import { useTranslation } from "react-i18next";

const V3PaymentMethods = ({ briefResponse, parentRefresh, isAdmin }: any) => {
  const { t } = useTranslation(['translationV2','translation'])
  const [moderatePayment, setModeratePayment] = useState<any>({
    service: "",
    status: "",
    reason: "",
  });

  const showOverrideButton =
    lodash.get(briefResponse, "rawResponse.status") === "APPROVED" &&
    (lodash.isNull(lodash.get(briefResponse, "rawResponse.payment.bankTransfer")) || lodash.isEmpty(lodash.get(briefResponse, "rawResponse.payment.bankTransfer"))) &&
    (lodash.isNull(lodash.get(briefResponse, "rawResponse.payment.po")) || lodash.isEmpty(lodash.get(briefResponse, "rawResponse.payment.po"))) &&
    lodash.isNull(lodash.get(briefResponse, "rawResponse.payment.onlinePayment")) &&
    !lodash.get(briefResponse, "rawResponse.payment.isPaymentOverridden");

  useEffect(() => {
    if (!briefResponse) return;
    setIsDisabled(shouldDisableUpload());
  }, [briefResponse]);

  const shouldDisableUpload = () => {
    if (
      ["PROCESSING","PAID","PO_ISSUED","PARTIAL_REFUND","REFUND"].includes(lodash.get(briefResponse,'rawResponse.payment.status')) ||
      (Array.isArray(
        lodash.get(briefResponse, `rawResponse.payment.bankTransfer`)
      ) &&
        lodash.get(briefResponse, `rawResponse.payment.bankTransfer`).length >
          0) ||
      (Array.isArray(lodash.get(briefResponse, `rawResponse.payment.po`)) &&
        lodash.get(briefResponse, `rawResponse.payment.po`).length > 0)
    ) {
      return true;
    }

    return false;
  };

  const [isDisabled, setIsDisabled] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  const handleOverridePayment = async () => {
    const briefId = lodash.get(briefResponse, "rawResponse.cart.briefId");
    await overridePaymentByAdmin(briefId);
    await parentRefresh();
  };

  const handleOnChange = async (type: string, data: any) => {
    setIsUploading(true)
    const typeFileUrls = lodash.get(
      briefResponse,
      `rawResponse.payment.${type}`,
      []
    );
    if (typeFileUrls && typeFileUrls.length > 0) {
      await removeBriefPaymentFile(
        typeFileUrls[0],
        type,
        briefResponse?.rawResponse
      );
    }

    await uploadBriefPaymentFile(
      [data?.file?.originFileObj],
      type,
      briefResponse?.rawResponse
    );
    await parentRefresh();
    setIsUploading(false)
    return false;
  };

  const handleOnRemove = async (type: string, url: any) => {
    setIsUploading(true)
    await removeBriefPaymentFile(url, type, briefResponse?.rawResponse);
    await parentRefresh();
    setIsUploading(false)
    return false;
  };

  const handlePaymentStatusChange = (paymentType: string, value: string) => {
    switch (value) {
      case "ACCEPTED":
        setModeratePayment({
          ...moderatePayment,
          service: paymentType,
          status: "ACCEPTED",
          reason: "",
        });
        // await acceptBriefPayment(lodash.get(briefResponse,'rawResponse.payment'));
        // await parentRefresh();
        break;
      case "REJECTED":
        setModeratePayment({
          ...moderatePayment,
          service: paymentType,
          status: "REJECTED",
          reason: "",
        });
        // await rejectBriefPayment(lodash.get(briefResponse,'rawResponse.payment'),null);
        // await parentRefresh()
        break;
      default:
        return false;
    }
  };

  const handleSubmitPaymentResponse = async () => {
    if (!moderatePayment.status || !moderatePayment.service) return;

    if (moderatePayment.status === "ACCEPTED") {
      await acceptBriefPayment(
        lodash.get(briefResponse, "rawResponse.payment")
      );
    } else if (moderatePayment.status === "REJECTED") {
      await rejectBriefPayment(
        lodash.get(briefResponse, "rawResponse.payment"),
        moderatePayment.reason
      );
    }

    setModeratePayment({ status: "", service: "", reason: "" });

    await parentRefresh();
  };

  const renderModeration = (paymentType: string) => {
    return (
      <div className="mt-10" key={paymentType}>
        {briefResponse?.rawResponse?.payment?.status === "PENDING" &&
          lodash.get(briefResponse, "rawResponse.payment.inReview", false) &&
          lodash.get(briefResponse, `rawResponse.payment.${paymentType}`) && lodash.get(briefResponse, `rawResponse.payment.${paymentType}`, []).length > 0 && (
            <>
              <Radio.Group
                className="mt-5"
                style={{ gap: "10px" }}
                onChange={(e: any) =>
                  handlePaymentStatusChange(paymentType, e.target.value)
                }
              >
                <Radio.Button value={"ACCEPTED"}>
                  <div>
                    <span><CheckOutlined
                              title={t("Accept")}
                              style={{ cursor: "pointer", color: "green" }}
                            /> {t("Accept")}</span>
                  </div>
                </Radio.Button>
                <Radio.Button value={"REJECTED"}>
                  <div>
                    <span><CloseOutlined
                              title={t("Reject")}
                              style={{ cursor: "pointer", color: "red" }}
                            /> {t("Reject")}</span>
                  </div>
                </Radio.Button>
              </Radio.Group>
            </>
          )}
        {moderatePayment.status === "REJECTED" &&
          moderatePayment.service === paymentType && (
            <QInput
              disabled={
                !lodash.get(
                  briefResponse,
                  "rawResponse.payment.inReview",
                  false
                )
              }
              className="mt-10"
              size="large"
              placeholder={t("Enter rejection reason")}
              onChange={(e: any) =>
                setModeratePayment({
                  ...moderatePayment,
                  reason: e.target.value,
                })
              }
            ></QInput>
          )}
        {lodash.get(briefResponse,`rawResponse.payment.${paymentType}`) && lodash.get(briefResponse,`rawResponse.payment.${paymentType}`,[]).length > 0 && briefResponse?.rawResponse?.payment?.status === 'REJECTED' && <div className="rejection-reason">
            {briefResponse?.rawResponse?.payment?.reason}
          </div> }
        {moderatePayment.service === paymentType && briefResponse?.rawResponse?.payment?.inReview && (
          <QButton
            htmlType="button"
            type="primary"
            className="full-width justify-center mt-10"
            size="small"
            onClick={handleSubmitPaymentResponse}
          >
            {t("Submit response")}
          </QButton>
        )}
      </div>
    );
  };

  const previewProtectedFile = async (fileUrl: string) => {
    const response: any = await downloadProtectedMedia(fileUrl);
    const url = URL.createObjectURL(response.data);
    window.open(url, "_blank");
    URL.revokeObjectURL(url);
    return;
  };

  const customItemRenderer = (type: string, files: any[]) => {
    return (
      <>
        {files.map((url: any, index: any) => {
          return (
            <div
              className="flex-row mt-15"
              key={index}
              style={{ background: "#f6f6f6", padding:5, borderRadius:5 }}
            >
              <div>
                <a style={{wordBreak:'break-all'}} onClick={() => previewProtectedFile(url)}>
                  <EyeOutlined /> {getFileNameFromUrl(url)}
                </a>{" "}
              </div>
              {["PENDING", "REJECTED"].includes(
                briefResponse?.rawResponse?.payment?.status
              ) && (
                <div>
                  {<DeleteOutlined onClick={() => handleOnRemove(type, url)} />}
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const renderBankTransfer = () => {
    return (
      <div>
        <div className="flex-row">
          <div className="text-bold">{t("Bank Transfer")}</div>
        </div>
        <div className="mt-10">
          <Upload
            onChange={(e) => handleOnChange("bankTransfer", e)}
            onRemove={(e) => handleOnRemove("bankTransfer", e)}
            multiple={false}
            customRequest={() => false}
            showUploadList={false}
            listType={"text"}
            maxCount={1}
            // disabled={true}
          >
            <QButton
              size="small"
              type="primary"
              htmlType="button"
              className="qu-button-outline xs-btn"
              disabled={isDisabled}
            >
              {t("Upload")}
            </QButton>
          </Upload>
        </div>
        {customItemRenderer(
          "bankTransfer",
          briefResponse?.rawResponse?.payment?.bankTransfer ?? []
        )}
        {renderModeration("bankTransfer")}
      </div>
    );
  };

  const renderPO = () => {
    return (
      <div>
        <div className="flex-row">
          <div className="text-bold">{t("Purchase Order")}</div>
        </div>
        <div className="mt-10">
          <Upload
            onChange={(e) => handleOnChange("po", e)}
            onRemove={(e) => handleOnRemove("po", e)}
            multiple={false}
            customRequest={() => null}
            showUploadList={false}
            listType={"text"}
            maxCount={1}
            // disabled={true}
          >
            <QButton
              size="small"
              type="primary"
              htmlType="button"
              className="qu-button-outline xs-btn"
              disabled={isDisabled}
            >
              {t("Upload")}
            </QButton>
          </Upload>
        </div>
        {customItemRenderer(
          "po",
          briefResponse?.rawResponse?.payment?.po ?? []
        )}
        {renderModeration("po")}
      </div>
    );
  };

  const renderOnlinePayment = () => {
    const onlinePayment = lodash.get(
      briefResponse,
      "rawResponse.payment.onlinePayment"
    );
    const currentPaymentStatus = onlinePayment?.status;
    const transactionId = onlinePayment?.transactionId;
    const declineReason = currentPaymentStatus === 'DECLINED' && onlinePayment?.declineReason;
    const refunds = onlinePayment?.refunds ?? [];
    const invoice = lodash.get(briefResponse,'rawResponse.payment.invoice')??[];

    const getCurrentPaymentClass = () => {
      if (currentPaymentStatus === "PAID") return "approved";
      if (currentPaymentStatus === "PROCESSING") return "pending";
      if (currentPaymentStatus === "DECLINED") return "rejected";
    };

    return (
      <>
        <div>
          <div className="text-bold">{t("Online Payment")}</div>
          <small>{t("Can only be done via client platform")}</small>
        </div>
        {!isAdmin && (
          <div className="mt-10">
            <QButton
              size="small"
              type="primary"
              htmlType="button"
              className="qu-button-outline xs-btn"
              disabled={true}
            >
              {t("Pay Now")}
            </QButton>
          </div>
        )}
        {onlinePayment && (
          <div className="mt-15" style={{background:"#f6f6f6",borderRadius:5, padding:5}}>
            {t("Transaction #")} {transactionId}
          </div>
        )}
        {onlinePayment && declineReason && (
          <div className={`mt-10 status-label ${getCurrentPaymentClass()}`}>
            {declineReason}
          </div>
        )}
        {onlinePayment && refunds.map(((refund:any,index:number)=>{
          return <div key={index} className="mt-10" style={{background:"rgb(255 240 240)", borderRadius:5, padding:5}}>
          {t("A refund of")} <span className="text-bold">{refund.currency} {Number(refund.amount/100).toFixed(2)}</span> {t("was made on")} <span className="text-bold">{moment(refund.createdAt).format(FORMATS.DATE_LL_FORMAT)}</span> {t("with")} <span className="text-bold">{t("Payment #")} {refund.paymentId}</span> 
        </div>
        }))}
        {invoice && invoice.length > 0 && <div>
          <a
            onClick={() => previewProtectedFile(invoice[0])}
            target="_blank"
            className="ant-btn qu-button full-width justify-center qu-button-soft mt-15 ant-btn-primary"
          >
            {t("View Invoice")}
          </a>
        </div> }
      </>
    );
  };

  return (
    <>
      {isUploading && <SpinnerCenter></SpinnerCenter>}

      {renderBankTransfer()}
      <Divider></Divider>

      {renderPO()}
      <Divider></Divider>

      {renderOnlinePayment()}
      {showOverrideButton && <Divider></Divider>}

      {showOverrideButton && (
        <QButton
          htmlType="button"
          className="full-width justify-center qu-button-soft"
          type="primary"
          onClick={handleOverridePayment}
        >
          {t("Override Payment")}
        </QButton>
      )}
    </>
  );
};

export default React.memo(V3PaymentMethods);
