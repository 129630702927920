import React, { useEffect, useState } from "react";
import {
  Col,
  Modal,
  QButton,
  QInput,
  qnotification,
  Row,
} from "quantum_components";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_TYPES } from "src/constants";

const ServiceV2Tags = ({
  selectedService,
}: any) => {
  const { t } = useTranslation(["translationProfile", "translationDashboard"]);

  const DOCUMENTATION = selectedService?.hasServerIntegration ? 
  "https://docs.google.com/document/d/1QeUO1RDLvR_Nky-GniHzUPt7uBP3R5BNzKh3hBzUdm0/edit?usp=sharing" :
  "https://docs.google.com/document/d/1AkxG3xli5RDZxmcXootePkX1_oWj42bYBP2OdeTo0NY/edit?usp=sharing";

  const copyCodeToClipboard = (value: string) => {
    var input: any = document.body.appendChild(document.createElement("input"));
    input.value = value;
    input.focus();
    input.select();
    document.execCommand("copy");
    input.parentNode.removeChild(input);
    qnotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: t("profile.public.Success"),
      description: t("profile.public.Copied successfully!!"),
    });
  };

  const renderTooltip = (tag: string) => {
    switch (tag) {
      case "[GET] json":
        return t(
          "profile.partner.available_services.multi_form.untargeted_json"
        );
      case "json":
        return (
          <>
            <div>
              {t(
                "profile.partner.available_services.multi_form.targeted_json_text"
              )}
            </div>
            -{" "}
            {t(
              "profile.partner.available_services.multi_form.targeted_json_key1"
            )}
            <br />-{" "}
            {t(
              "profile.partner.available_services.multi_form.targeted_json_key2"
            )}
            <br />-{" "}
            {t(
              "profile.partner.available_services.multi_form.targeted_json_key3"
            )}
            <br />-{" "}
            {t(
              "profile.partner.available_services.multi_form.targeted_json_key4"
            )}
            <br />-{" "}
            {t(
              "profile.partner.available_services.multi_form.targeted_json_key5"
            )}
          </>
        );
      default:
        return t(
          "profile.partner.available_services.multi_form.targeted_script"
        );
    }
  };

  const tag = selectedService?.adspace?.tag;

  const renderTags = () => {
    return Object.entries(tag).map(([key, value]: any) => {
        if(value){
            return (
              <Row
                className="p-5 mb-10"
                key={key}
                gutter={16}
                justify="center"
                align="middle"
              >
                <Col span={24}>
                  <div className="p-0 m-0 text-bold">
                    {" "}
                    {String(key).toLocaleUpperCase()}
                    <Tooltip data-html="true" title={renderTooltip(key)}>
                      <span
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontStyle: "italic",
                          color: "#aaaaaa",
                          cursor: "pointer",
                        }}
                      >
                        ?
                      </span>
                    </Tooltip>
                  </div>
                </Col>
                <Col span={16}>
                  {value !== Object(value) && (
                    <QInput
                      size="large"
                      disabled={true}
                      value={String(value).trim()}
                    />
                  )}
                </Col>
                <Col span={8}>
                  <QButton
                    type="primary"
                    htmlType="button"
                    size="middle"
                    className="qu-button qu-button-outline"
                    onClick={() =>
                      copyCodeToClipboard(String(value).trim())
                    }
                  >
                    {t("profile.public.Copy")}
                  </QButton>
                </Col>
              </Row>
            )
        }
    }
)
  }

  return (
    <>
        {tag && (
          <div
            className="p--sm tc--light mt-15 mb-25 p-10"
            style={{ border: "4px dashed" }}
          >
            <div>
              {
                !selectedService?.hasServerIntegration &&
                <>
                  <p className="m-0 mb-5">{t("profile.public.Please_copy")}</p>
                  {renderTags()}
                </>
                
              }
              
              <p className="mt-5 mb-5 text-center">
                {t("profile.public.doc")}{" "}
                <a
                  href={DOCUMENTATION}
                  target="_blank"
                >
                  {t("profile.QuantumDocx")}
                </a>{" "}
              </p>
            </div>
          </div>
        )}
    </>
  );
};

export default ServiceV2Tags;
