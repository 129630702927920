import React, { ReactElement, useEffect, useState } from "react";

import { Col, Row, QButton, Form, QRangePicker } from "quantum_components";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { cloneDeep } from "lodash";
import { actions } from "../dashboard.module";
import { getParams } from "../dashboard.selectors";
import {
  BUTTON_DATES,
  BUTTON_DATES_NAMES,
  CHART_TYPES,
  DATE_PRESET,
} from "../../../constants/dashboard";
import * as coreActions from "../../core/core.actions";
import DashboardFilter from "./components/DashboardFilter";
import SignUpChart from "./components/SignUpChart";
import ClientsChart from "./components/ClientsChart";
import PartnersChart from "./components/PartnersChart";
import DistChart from "./components/DistributionChart";
import RevenueChart from "./components/RevenueChart";
import ServiceTypeChart from "./components/ServiceTypeChart";
import BriefChart from "./components/BriefChart";
import CampaignChart from "./components/CampaignChart";
import TransactionChart from "./components/TransactionsChart";
import ActiveUserChart from "./components/ActiveUserChart";
import CampaignCompletionChart from "./components/CampaignCompletionChart";
import { CORE_CONSTANTS } from "../../../constants/settings";
import { FORMATS } from "src/constants";
import { getDateFromToday, getIsoDate } from "src/helpers/datesHelper";
import { IDashboardFilterParams } from "../dashboard.types";
import { useTranslation } from "react-i18next";

export default function DashboardContainer(): ReactElement {
  const [form] = Form.useForm();

  const { t } = useTranslation(["translationDashboard"]);

  const dispatch = useDispatch();
  const params = useSelector(getParams);

  const [activeFilter, setActiveFilter] = useState<CHART_TYPES | null>(null);

  useEffect(() => {
    dispatch(actions.setParamsAndFetchChart(params));

    // dispatch(coreActions.fetchSettings([CORE_CONSTANTS.ADMIN_DASHBOARD]));
    dispatch(coreActions.fetchConstants([CORE_CONSTANTS.ADMIN_DASHBOARD]));

    return () => {
      dispatch(actions.resetDashboard());
    };
  }, []);

  const handleOpenFilter = (type: CHART_TYPES) => {
    setActiveFilter(type);
  };

  const handleClose = () => {
    setActiveFilter(null);
  };

  const handleDatePicker = (range: any) => {
    if (!range) return;
    const start = range && getIsoDate(range[0].startOf("day"));
    const end = range && getIsoDate(range[1].endOf("day"));

    form.setFieldsValue({ date: [moment(start), moment(end)] });
  };

  const onFinish = async (formValues: any) => {
    if (formValues.date) {
      const newParams = cloneDeep(params) as IDashboardFilterParams;
      for (var [key, value] of Object.entries(newParams)) {
        if (value?.before || value?.after) {
          value.before = formValues.date && formValues.date[0];
          value.after = formValues.date && formValues.date[1];
        }
      }
      await dispatch(actions.setParamsAndFetchChart(newParams, true));
    }
  };

  const handlePresetButton = (preset: DATE_PRESET) => {
    const start = getIsoDate(getDateFromToday(preset));
    const end = getIsoDate(moment().endOf("day"));
    form.setFieldsValue({ date: [moment(start), moment(end)] });
  };

  const handleSave = () => {
    form.submit();
  };

  return (
    <div className="qu-dashboard">
      <h1 className="mb-30">{t("dashboard.title")}</h1>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={24} justify="space-between" style={{ alignItems: "center" }}>
          <Col xs={24} lg={12}>
            <Form.Item label={t("dashboard.date")} name="date">
              <QRangePicker
                placeholder={[
                  t("dashboard.start_date"),
                  t("dashboard.end_date"),
                ]}
                allowClear={false}
                format={FORMATS.DATE_FORMAT}
                onChange={handleDatePicker}
                className="full-width force-ltr"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            {BUTTON_DATES.map((button: DATE_PRESET) => {
              return (
                <QButton
                  key={button}
                  className="qu-button-outline mr-5 mt-5"
                  onClick={() => handlePresetButton(button)}
                >
                  {t(`dashboard.filters.${BUTTON_DATES_NAMES[button]}`)}
                </QButton>
              );
            })}
            <QButton className="mt-5" type="primary" onClick={handleSave}>
              {t("dashboard.filter_all_charts")}
            </QButton>
          </Col>
        </Row>
      </Form>

      <Row gutter={24} className="pt-20">
        {/* <Col span={8}>
          <SignUpChart openFilter={handleOpenFilter} />
        </Col> */}
        <Col xs={24} span={12}>
          <ClientsChart openFilter={handleOpenFilter} />
        </Col>
        <Col xs={24} span={12}>
          <RevenueChart openFilter={handleOpenFilter} />
        </Col>
        {/* <Col span={8}>
          <PartnersChart openFilter={handleOpenFilter} />
        </Col> */}
      </Row>
      {/* <Row gutter={24}>
        <Col span={24}>
          <DistChart openFilter={handleOpenFilter} />
        </Col>
      </Row> */}
      <Row gutter={24}>
        <Col xs={24} span={12}>
          <ServiceTypeChart openFilter={handleOpenFilter} />
        </Col>
        <Col xs={24} span={12}>
          <BriefChart openFilter={handleOpenFilter} />
        </Col>
      </Row>
      <CampaignChart openFilter={handleOpenFilter} />
      <Row gutter={24}>
        <Col xs={24} span={16}>
          <TransactionChart openFilter={handleOpenFilter} />
        </Col>
        <Col xs={24} span={8}>
          <CampaignCompletionChart openFilter={handleOpenFilter} />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <ActiveUserChart openFilter={handleOpenFilter} />
        </Col>
      </Row>
      {activeFilter && (
        <DashboardFilter chartType={activeFilter} onClose={handleClose} />
      )}
    </div>
  );
}
