import { Card, IconsCommon, QButton } from 'quantum_components'
import React from 'react'
import { useSelector } from 'react-redux'
import { getActiveUsersChartData, getParams } from '../../dashboard.selectors'
import { CHART_TYPES } from '../../../../constants/dashboard'
import { abbreviateNumber } from '../../../../helpers/abbreviateNumber'
import { IChartDataObjectNameValue } from '../../dashboard.types'
import moment from 'moment'
import { FORMATS } from 'src/constants'
import { getLocaleCostWithoutDecimal } from 'src/helpers/currency'
import { useTranslation } from 'react-i18next'

interface IProps {
  openFilter: (type: CHART_TYPES) => void
}

const ActiveUserChart = ({ openFilter }: IProps) => {
  const data = useSelector(getActiveUsersChartData)
  const params = useSelector(getParams)
  const {t} = useTranslation(['translationDashboard'])

  return (
    <Card className="qu-chart-card qu-metrics-card mb-20">
      <div className="card-header">
        <div>
          <h3>{t("dashboard.active_users")}</h3>
          <small>
            {moment(params[CHART_TYPES.ACTIVE_USERS].before).format(FORMATS.DATE_ALPHA_FORMAT)} -{' '}
            {moment(params[CHART_TYPES.ACTIVE_USERS].after).format(FORMATS.DATE_ALPHA_FORMAT)}
          </small>
          <br />
          <small>
          {t("dashboard.total")}:{' '}
            {getLocaleCostWithoutDecimal(
              data.reduce(function (accumulator, item) {
                return accumulator + item.value
              }, 0)
            )}
          </small>
        </div>
        <div className="card-header-right">
          <QButton
            className="qu-button-outline qu-brief-filter-button"
            onClick={() => openFilter(CHART_TYPES.ACTIVE_USERS)}
          >
            <span className="h-24">
              <IconsCommon.FilterIcon />
            </span>
          </QButton>
        </div>
      </div>
      {data.map((item: IChartDataObjectNameValue) => {
        const value = item.value ? abbreviateNumber(item.value) : '-'
        return item.name !== 'Partner' && (
          <Card.Grid key={item.name} style={{ width: '50%' }}>
            <div className="card-body">
              <div className="card-number">{value}</div>
            </div>
            <div className="card-footer">{t(`dashboard.${item.name}`)}</div>
          </Card.Grid>
        )
      })}
    </Card>
  )
}

export default ActiveUserChart
