import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'antd'
import {
  Form,
  IconsCommon,
  InfoCard,
  Modal,
  ProfileCard,
  ProgressLabel,
  PublicProfileField,
  QAlert,
  QButton,
  QInput,
  qnotification,
} from 'quantum_components'

import { actions } from '../publicProfile.module'
import UploadComponent from 'src/components/UploadComponent'
import {
  getPartnerDetails,
  getPartnerDetailsLoading,
  getPartnerPublicPercent,
  getPublicProfile as getPublicProfileFromPublicModule,
  getPublicProfileLoading,
} from '../publicProfile.selectors'
import { getForm, getPartnerProfileSettings, getPublicProfile } from '../../partner-Profile/profile.selectors'
import { getCurrency, getCurrentUser, getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'
import { getFileNameFromUrl } from 'src/utils'
import { useReduxDispatch } from 'src/helpers'

import IUploadFile from '../../../../interfaces/IUploadFile'
import { TName } from '../../../../interfaces/IAction'

import { ReactComponent as CloseIcon } from 'src/assets/icons/close-icon.svg'
import { ReactComponent as PreviewIcon } from 'src/assets/icons/open-icon.svg'
import BackgroundImage from 'src/assets/backgroundImage.jpg'
import '../styles.scss'
import { useParams } from 'react-router-dom'
import { initialState } from '../publicProfile.reducer'
import { pick } from 'lodash'
import { abbreviateNumber } from 'src/helpers/abbreviateNumber'
import PartnerDetailsModal from 'src/components/PartnerDetailsComponents/PartnerDetailsModal'
import * as partnerProfileActions from '../../partner-Profile/Profile.actions'
import {
  calculateLowestValue,
  getDistributionPrices,
  getDynamicDistributionPrices,
  getMediaBuyPartnerPrice,
  getMediaBuyPartnerPriceForMetrics,
  getMediaBuyTypesPrices,
  getMediaBuyTypesPricesForMetrics,
  renderRoundedNumber,
} from 'src/helpers/currency'
import { IPartnerCardService, MediaSubtype } from '../../../brief/brief.types'
import { DISTRIBUTION_TYPE, MEDIA_TYPES } from 'src/constants/brief'
import { DynamicPricingTypeLabels, NAMES_PUBLIC_PROFILE_FIELDS } from 'src/constants/profile'
import { IMediaBuyTypesElement } from '../../partner-Profile/profile.types'
import { hasPermission } from 'src/helpers/permission'
import { PERMISSIONS } from 'src/constants/permission'
import { useTranslation } from 'react-i18next'
import SpinnerCenter from 'src/components/Spinner/spinner'
import { NOTIFICATION_TYPES } from 'src/constants'
import { getServicesV2List } from 'src/modules/servicesV2/servicesV2.actions'

export interface Props {
  removeHandler?: () => void
  removable?: boolean
  value: any
}

const PublicProfile = () => {
  const { t } = useTranslation(['translationProfile'])
  const [form] = Form.useForm()
  const dispatch = useReduxDispatch()
  const getProfile = useSelector(getPublicProfile)
  let partnerForm = useSelector(getForm)
  const partnerDetails = useSelector(getPartnerDetails)
  const partnerDetailsLoading = useSelector(getPartnerDetailsLoading)
  const filterSettings = useSelector(getPartnerProfileSettings)
  const publicPercent = useSelector(getPartnerPublicPercent)
  const publicProfile = useSelector(getPublicProfileFromPublicModule)
  const isUpdating = useSelector(getPublicProfileLoading)
  const permissions = useSelector(getCurrentUserPermissionActions)
  const currency = useSelector(getCurrency)
  const currentUser = useSelector(getCurrentUser)

  const disabledSave = isUpdating || !hasPermission(permissions, PERMISSIONS.PARTNER_UPDATE_PROFILE)

  const id = currentUser.partnerUserId
  const [loading, setLoading] = useState(true)

  const metrics = Array.from({ length: 5 }, (v, i) =>
    publicProfile.metricsMarketing ? publicProfile.metricsMarketing[i] : ''
  )

  const initialValues = {
    [NAMES_PUBLIC_PROFILE_FIELDS.LISTING_SHORT_DESCRIPTION]: publicProfile.listingShortDescription,
    [NAMES_PUBLIC_PROFILE_FIELDS.HIGHLIGHTS]: publicProfile.highlights?.length ? publicProfile.highlights : [''],
    [NAMES_PUBLIC_PROFILE_FIELDS.METRICS_MARKETING]: metrics,
  }

  const [files, setFiles] = useState<{ [x: string]: IUploadFile[] }>({})
  const [filesObjectLoading, setFilesObjectLoading] = useState({
    companyLogo: false,
    previewCard: false,
    listingHeader: false,
  })

  const [showPreview, setShowPreview] = useState<boolean>(false)

  useEffect(() => {
    if (JSON.stringify(initialState.publicProfile) !== JSON.stringify(getProfile)) {
      getProfile.id !== 0 && setLoading(false)
    }
  }, [getProfile])

  const fetchFilterSettings = () => {
    dispatch(partnerProfileActions.fetchPartnerProfileSettings())
  }

  useEffect(()=>{
    const fetchAvailableServices = async () => {
      const data = await getServicesV2List();
      if(data?.results?.length > 0){
        partnerForm['mediaBuyTypes'] = data?.results;
      }
    }
    fetchAvailableServices();
  },[])

  useEffect(() => {
    fetchFilterSettings()
    // return () => {
    //   dispatch(actions.unMountClearData())
    // }

  }, [])

  const distributionPrices = partnerForm.hasDynamicSamplePricing
    ? getDynamicDistributionPrices(partnerForm)
    : getDistributionPrices(partnerForm)
  const distributionLowestPrice = calculateLowestValue(distributionPrices)
  const servicePrices =
    partnerForm.mbPricingModel === 'metrics'
      ? getMediaBuyTypesPricesForMetrics(partnerForm?.mediaBuyTypes)
      : getMediaBuyTypesPrices(partnerForm?.mediaBuyTypes)
  const mediaBuyLowestPrice = calculateLowestValue(servicePrices)

  const getMediaTypes = () => {
    partnerForm.mbPricingModel === 'metrics'
      ? partnerForm.mediaBuyTypes.sort(
        (a, b) => getMediaBuyPartnerPriceForMetrics(a) - getMediaBuyPartnerPriceForMetrics(b)
      )
      : partnerForm.mediaBuyTypes.sort((a, b) => getMediaBuyPartnerPrice(a) - getMediaBuyPartnerPrice(b))

    const services = [] as IPartnerCardService[]
    if (partnerForm.isSampleDistribution) {
      if (partnerForm.hasDynamicSamplePricing && partnerForm.dynamicSamplePrices) {
        Object.entries(partnerForm.dynamicSamplePrices).map(([key, value]) => {
          const price = renderRoundedNumber(value, currency)
          const sampleService = {
            name: DISTRIBUTION_TYPE.PRODUCT_SAMPLE + ': ' + DynamicPricingTypeLabels[key],
            price: price,
            type: MEDIA_TYPES.DISTRIBUTION,
          } as IPartnerCardService

          services.push(sampleService)
        })
      } else {
        const price = renderRoundedNumber(partnerForm.pricePerSample, currency)
        const sampleService = {
          name: DISTRIBUTION_TYPE.PRODUCT_SAMPLE,
          price: price,
          type: MEDIA_TYPES.DISTRIBUTION,
        } as IPartnerCardService

        services.push(sampleService)
      }
    }
    if (partnerForm.isInsertDistribution) {
      const price = renderRoundedNumber(partnerForm.pricePerInsert, currency)
      const insertService = {
        name: DISTRIBUTION_TYPE.PRINTED_INSERT,
        price: price,
        type: MEDIA_TYPES.DISTRIBUTION,
      } as IPartnerCardService

      services.push(insertService)
    }

    const mediaBuying = partnerForm.mediaBuyTypes.map((service: IMediaBuyTypesElement) => {
      const mediaSubTypes = filterSettings.mediaSubtypes
      const subType = mediaSubTypes ? mediaSubTypes.find((type: MediaSubtype) => type.name === service.name) : null
      const price =
        partnerForm.mbPricingModel === 'metrics'
          ? getMediaBuyPartnerPriceForMetrics(service)
          : getMediaBuyPartnerPrice(service)

      return {
        name: service.subName ? service.subName : service.name,
        price: renderRoundedNumber(price, currency),
        type: 'Media Buying',
        subtype: subType && subType.subtype,
      } as IPartnerCardService
    })

    return [...services, ...mediaBuying]
  }

  // const cardOptions = {
  //   preview: true,
  //   serviceLabel: 'ECOMMERCE',
  //   name: partnerForm.companyName,
  //   url: partnerForm.companyWebsite,
  //   description: publicProfile.listingShortDescription
  //     ? publicProfile.listingShortDescription
  //     : 'Listing short description here',
  //   social: [
  //     {
  //       name: 'Customers',
  //       counter: partnerForm.metrics?.Leads ? abbreviateNumber(partnerForm.metrics.Leads) : 0,
  //     },
  //     {
  //       name: 'Impressions',
  //       counter: partnerForm.metrics?.Looks ? abbreviateNumber(partnerForm.metrics.Looks) : 0,
  //     },
  //     {
  //       name: 'Facebook',
  //       counter: partnerForm.metrics?.Facebook ? abbreviateNumber(+partnerForm.metrics.Facebook) : 0,
  //     },
  //     {
  //       name: 'Instagram',
  //       counter: partnerForm.metrics?.Instagram ? abbreviateNumber(+partnerForm.metrics.Instagram) : 0,
  //     },
  //     {
  //       name: 'Twitter',
  //       counter: partnerForm.metrics?.Twitter ? abbreviateNumber(+partnerForm.metrics.Twitter) : 0,
  //     },
  //   ],
  //   servicesPackagePrice: renderRoundedNumber(distributionLowestPrice, currency),
  //   servicesDevicePrice: renderRoundedNumber(mediaBuyLowestPrice, currency),
  //   headerImg: publicProfile.previewCardImage ? publicProfile.previewCardImage : BackgroundImage,
  //   logo: publicProfile.companyLogo ? publicProfile.companyLogo : BackgroundImage,

  //   popupServices: getMediaTypes(),
  // }

  const onFinish = (values: any) => {
    dispatch(actions.fetchPublicProfileSave(id))
  }

  const onValuesChange = (value: any, allValues: any) => {
    dispatch(
      actions.setPublicProfile({
        ...allValues,
        metricsMarketing: allValues.metricsMarketing.filter((e: string) => e),
        highlights: allValues.highlights.filter((e: string) => e),
      })
    )
  }

  const handleUpload = async (uploads: File[], name: TName) => {
    setFilesObjectLoading(prevState => ({ ...prevState, [name]: true }))
    dispatch(actions.uploadProductImage(uploads, name, id))
  }

  const handleRemove = async (name: TName) => {
    dispatch(actions.imageRemove(name))
    setFiles({ ...files, [name]: [] })
  }
  // Public profile within Partner profile
  const fetchPublicProfile = () => {
    dispatch(partnerProfileActions.fetchPartnerProfile(id))
  }

  useEffect(() => {
    fetchPublicProfile()
  }, [id])

  const filesMapper = () => {
    const fieldsImages: any = pick(publicProfile, [
      NAMES_PUBLIC_PROFILE_FIELDS.COMPANY_LOGO,
      NAMES_PUBLIC_PROFILE_FIELDS.LISTING_HEADER_IMAGE,
      NAMES_PUBLIC_PROFILE_FIELDS.PREVIEW_CARD_IMAGE,
    ])
    const fieldKeys: string[] = Object.keys(fieldsImages)
    let obj = {}
    fieldKeys.map((key, i) => {
      obj = {
        ...obj,
        //@ts-ignore
        [key]: publicProfile[key]
          ? [
            {
              uid: i + '',
              name: getFileNameFromUrl(fieldsImages[key]),
              status: 'done',
              url: fieldsImages[key],
            } as IUploadFile,
          ]
          : [],
      }
      return key
    })
    setFiles(obj)
  }

  useEffect(() => {
    filesMapper()
    setFilesObjectLoading({
      companyLogo: false,
      previewCard: false,
      listingHeader: false,
    })
  }, [publicProfile])

  const handleOpenPreview = async () => {
    if (!id) {
      return
    }

    await dispatch(actions.fetchPartnerDetails(parseInt(id)))
    setShowPreview(true)
  }

  const handleMore = (e: any) => {
    e.preventDefault()
    handleOpenPreview()
  }

  const copyPreviewLinkToClipboard = () => {
    var input: any = document.body.appendChild(document.createElement('input'))
    input.value = `${window.location.protocol}//${window.location.hostname}/deployment-details`;
    input.focus()
    input.select()
    document.execCommand('copy')
    input.parentNode.removeChild(input)
    qnotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: t('profile.public.Success'),
      description: t('profile.public.Copied successfully!!'),
    })
  }

  if (loading) return <p></p>
  return (
    <div className="publicProfile partner-container">
      {partnerDetailsLoading && <SpinnerCenter />}
      <Form
        name="publicProfile"
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <div className="mb-20" style={{ maxWidth: '540px' }}>
          <div className="public-profile-title">
            <h1>{t("profile.public.public_profile")}</h1>
            <h5>
              {t("profile.public.description")}
            </h5>
          </div>
        </div>
        <Row gutter={20}>
          <Col xs={24} sm={24} md={16}>
            <InfoCard
              headerLeft={<h2 className="h3">{t("profile.public.profile_preview_information")}</h2>}
              headerRight={<ProgressLabel value={publicPercent} label={t("profile.public.completion")} />}
            >
              <PublicProfileField
                description={{
                  title: t("profile.public.listing_short_description"),
                }}
                input={
                  <Form.Item name="listingShortDescription">
                    <QInput className="form-textarea" placeholder={t("profile.public.listing_explanation")} />
                  </Form.Item>
                }
              />
              <PublicProfileField
                description={{
                  title: t("profile.public.company_logo"),
                }}
                input={
                  <Form.Item>
                    <UploadComponent
                      files={files.companyLogo}
                      onChange={value => handleUpload(value, 'companyLogo')}
                      onRemove={() => handleRemove('companyLogo')}
                      isUploading={filesObjectLoading.companyLogo}
                    />
                  </Form.Item>
                }
              />
              {/* TO-DO: Refactor, Remove from BE as well */
              /*<PublicProfileField
                description={{
                  title: t("profile.public.preview_card_image"),
                }}
                input={
                  <Form.Item>
                    <UploadComponent
                      files={files.previewCardImage}
                      onChange={value => handleUpload(value, 'previewCardImage')}
                      onRemove={() => handleRemove('previewCardImage')}
                      isUploading={filesObjectLoading.previewCard}
                    />
                  </Form.Item>
                }
              />*/}
              <PublicProfileField
                description={{
                  title: t("profile.public.listing_header_image"),
                }}
                input={
                  <Form.Item>
                    <UploadComponent
                      files={files.listingHeaderImage}
                      onChange={value => handleUpload(value, 'listingHeaderImage')}
                      onRemove={() => handleRemove('listingHeaderImage')}
                      isUploading={filesObjectLoading.listingHeader}
                    />
                  </Form.Item>
                }
              />
              <PublicProfileField
                description={{
                  title: t("profile.public.highlights"),
                  subTitle:
                    t("profile.public.highlights_help_text")
                }}
                input={
                  <>
                    <Form.List name="highlights">
                      {(fields, { add, remove }) => {
                        return (
                          <div key="a">
                            <div key="b" className="PPField__inputList">
                              {fields.map((field,index) => (
                                <div key={index+"h"} className="input">
                                  {/* name={field.name} fieldKey={field.fieldKey} */}
                                  <Form.Item {...field}>
                                    <QInput
                                      className="form-textarea input-with-close-icon"
                                      placeholder={t("profile.public.highlights_example")}
                                    />
                                  </Form.Item>
                                  {fields.length > 1 && (
                                    <CloseIcon
                                      key={index+"c"}
                                      className="closeIcon"
                                      onClick={() => remove(field.name)}
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                            <div className="PPField__inputAdd" onClick={() => add()}>
                              {t("profile.public.add_another_highlight")}
                            </div>
                          </div>
                        )
                      }}
                    </Form.List>
                  </>
                }
              />
              <PublicProfileField
                description={{
                  title: t("profile.public.metrics"),
                  subTitle: t("profile.public.metrics_help_text")

                }}
                input={
                  <>
                    <Form.List name="metricsMarketing">
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            <div className="PPField__inputList">
                              {fields.map((field,index) => (
                                <div key={index+"m"} className="input">
                                  {/* name={field.name} fieldKey={field.fieldKey} */}
                                  <Form.Item {...field}>
                                    <QInput
                                      className="form-textarea"
                                      type="text"
                                      placeholder={t("profile.public.metrics_example")}
                                    />
                                  </Form.Item>
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      }}
                    </Form.List>
                  </>
                }
              />
            </InfoCard>
            <div className="public-profile-footer">
              <QButton onClick={handleOpenPreview} className="qu-button-soft preview">
                {t("profile.public.preview")}
              </QButton>
              <QButton disabled={disabledSave} type="primary" htmlType="submit">
                {t("profile.partner.save_updates")}
              </QButton>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <div className="profile-card-preview">
              {/*<ProfileCard onClick={handleMore} {...cardOptions} />
              <div className="pt-10 pb-10">
                <QAlert
                  className="centered small transparent"
                  type="info"
                  message={t("profile.public.partner_card_help_text")}
                />
              </div>*/}
              <QButton onClick={handleOpenPreview} block className="qu-button-soft justify-between PreviewIcon">
                {t("profile.public.preview")}
                <PreviewIcon className="ProfileCard__serviceLabel" />
              </QButton>

              <QButton className="qu-button-outline mt-10 ant-btn-block justify-between" onClick={copyPreviewLinkToClipboard}>
                {t("profile.public.Copy Preview Link")}
              </QButton>
            </div>
          </Col>
        </Row>
      </Form>

      <Modal
        wrapClassName="qu-modal qu-modal-details"
        closeIcon={<IconsCommon.IconClose />}
        visible={showPreview}
        onCancel={() => setShowPreview(false)}
        footer={null}
      >
        {showPreview && <PartnerDetailsModal details={partnerDetails} isLoading={partnerDetailsLoading} />}
      </Modal>
    </div>
  )
}
export default PublicProfile
